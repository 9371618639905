<template>
  <div class="bg-white py-8 px-8 rounded-md shadow">
    <div class="flex flex-row justify-start gap-4 items-center">
      <base-select v-model="subscriptionType" :options="options" />
      <search-box
        v-model="searchBy"
        placeholder="Search subscription..."
        @keyup.enter="fetchSubscriptionsBySearch"
        @clear="clearSearch"
      />
    </div>
    <div class="mt-8">
      <h2 class="text-sm font-bold text-gray-600">My Subscriptions</h2>
      <div v-if="subscriptionDelay" class="mt-4 flex flex-row flex-wrap gap-4 sm:gap-8 justify-start">
        <Subscription
          v-for="subscription in subscriptions"
          :key="subscription.id + '_' + subscription.status"
          :subscription="subscription"
          class="hover:cursor-pointer"
          @open-sidebar="openSidebar(subscription.id)"
        />
        <div v-if="subscriptions.length == 0" class="flex flex-row">
          <p class="text-sm font-medium text-gray-600">You didn't subscribe to any plugins yet.</p>
        </div>
        <!-- <FetchProgressLoader v-if="isNextFetch" as="template" /> -->
      </div>
      <ProcessingLoader v-else as="template" class="mt-4" />
    </div>
  </div>
  <SidePanelContainer :id="id" :show="open" @closeSidebar="close" />
</template>

<script>
import BaseSelect from '../../components/BaseSelect.vue'
import Subscription from './partials/Subscription.vue'
import SidePanelContainer from './partials/SidePanel.vue'
import { mapGetters, mapState } from 'vuex'
import ProcessingLoader from '../subscriptions/partials/SubscriptionsPreloader.vue'
//import FetchProgressLoader from '../../components/FetchProgressLoader.vue'
import SearchBox from '../../components/SearchBox.vue'

const options = [
  { id: 'all', name: 'All Subscription' },
  { id: 'TRIAL', name: 'Trial Subscriptions' },
  { id: 'TRIAL_EXPIRED', name: 'Trial Expired Subscriptions' },
  { id: 'Live', name: 'Live Subscriptions' },
  { id: 'CANCELLED', name: 'Cancelled Subscriptions' },
]

export default {
  components: {
    BaseSelect,
    Subscription,
    SidePanelContainer,
    ProcessingLoader,
    //FetchProgressLoader,
    SearchBox,
  },
  data() {
    return {
      options,
      open: false,
      id: null,
      subscriptionType: 'all',
      searchBy: null,
      subscriptionDelay: false,
    }
  },
  methods: {
    openSidebar(id) {
      this.id = id
      this.open = true
    },
    close() {
      this.open = false
    },
    clearSearch() {
      this.searchBy = null
      this.fetchSubscriptionsBySearch()
    },
    async fetchSubscription(params) {
      await this.$store
        .dispatch('subscription/fetchSubscriptions', params)
        .then(() => {
          this.subscriptionDelay = true
        })
        .catch((err) => {
          const notification = {
            type: 'error',
            message: err,
          }
          this.$store.dispatch('notification/add', notification, { root: true })
        })
    },
    fetchSubscriptionsBySearch() {
      this.subscriptionDelay = false
      this.page = 1
      this.fetchSubscription({ search: this.searchBy, page: this.page, per_page: 20 })
    },
  },
  watch: {
    subscriptionType: {
      handler: function () {
        let filter_by = this.subscriptionType == 'all' ? '' : 'status.' + this.subscriptionType
        this.fetchSubscription({ filter_by: filter_by, page: this.page, per_page: 20 })
      },
    },
    searchBy: {
      handler: function () {
        if (this.searchBy == '') {
          this.fetchSubscriptionsBySearch()
        }
      },
    },
  },
  async created() {
    await this.fetchSubscription({ page: this.page, per_page: 20 })
  },
  computed: {
    ...mapState('subscription', ['subscriptions']),
    ...mapGetters('subscription', ['subsCount']),
    page() {
      return parseInt(this.$route.query.page) || 1
    },
  },
}
</script>
