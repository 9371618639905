<template>
  <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white pt-8 shadow sm:rounded-lg">
        <div class="flex items-center justify-center pb-6 border-b border-gray-200">
          <img src="/logo-dark.png" class="w-56" alt="" />
        </div>
        <div class="mt-4 px-6 flex flex-col items-center">
          <h2 class="text-md font-bold text-gray-600 mt-2 mb-6">Thanks for signing up!</h2>
          <span class="text-sm font-normal text-gray-500">
            We have sent a verification <span class="font-medium">URL</span> to your email. Please verify your account
            by clicking on the link we just emailed to you.
          </span>
        </div>
        <div class="mt-6 sm:mt-8 px-6 flex flex-row justify-end pt-4 pb-6 bg-gray-50 sm:rounded-bl-lg sm:rounded-br-lg">
          <div>
            <LogoutButton />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoutButton from '@/components/LogoutButton.vue'
export default {
  components: {
    LogoutButton,
  },
}
</script>
