<template>
  <div class="grid grid-cols-12 gap-4">
    <div class="pb-4 col-span-3 inline-block">
      <router-link :to="{ name: 'products' }"><ArrowLeftIcon class="h-5 w-5" /></router-link>
    </div>
  </div>

  <div class="bg-white py-6 px-4 sm:py-8 sm:px-8 rounded-md shadow">
    <div class="flex flex-wrap gap-2 justify-between items-center">
      <h2 class="text-sm text-gray-600 font-semibold">Plan List of {{ product_name }}</h2>
      <button
        type="button"
        class="
          inline-flex
          items-center
          px-4
          py-1
          border
          bg-indigo-200
          border-indigo-200
          rounded-md
          text-indigo-600
          font-semibold
          text-sm
        "
        @click="syncPlan"
        :disabled="loading"
      >
        <Spinner v-if="loading" :size="4" :color="'text-blue-500'" />
        <span class="ml-2">Sync Plans</span>
      </button>
    </div>
    <div class="mt-4 sm:mt-8">
      <div class="overflow-x-auto sm:rounded">
        <table class="min-w-full">
          <thead class="bg-gray-100">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Plan Name
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Type
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Price
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th scope="col" class="relative px-6 py-3 w-20">
                <span class="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody v-if="planDelay">
            <tr
              v-for="(product, index) in plans"
              :key="product.id"
              :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
            >
              <td class="px-6 py-2 text-sm font-medium text-gray-500">
                {{ product.name }}
              </td>
              <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                {{ mappedIntervalUnit(product.interval_unit) }}
              </td>
              <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 font-semibold">
                {{ `$` + product.recurring_price }}
              </td>
              <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
                {{ capitalizeFirstLetter(product.status) }}
              </td>
              <td class="px-4 py-2 text-center">
                <!-- <router-link :to="{ name: 'updatePlugin', params: { id: product.id } }">
                  <PencilAltIcon class="h-5 w-5 text-indigo-400" />
                </router-link> -->
                <button @click="openView(product.id)" class="">
                  <PencilAltIcon class="h-5 w-5 text-indigo-400" />
                </button>
              </td>
            </tr>
            <tr v-if="isNextFetch">
              <td colspan="4">
                <FetchProgressLoader as="template" />
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="4">
                <ProcessingLoader as="template" class="mt-4" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <side-view
    :plugin-id="pluginId"
    :plan-edit-delay="planEditDelay"
    @updateDelay="updateDelay"
    :show="open"
    @closeSidebar="close"
  />
</template>

<script>
import { ArrowLeftIcon, PencilAltIcon } from '@heroicons/vue/outline'
import SideView from './partials/EditSidebar.vue'
import { mapGetters } from 'vuex'
import ProcessingLoader from '../../components/ProcessingLoader.vue'
import FetchProgressLoader from '../../components/FetchProgressLoader.vue'
import Spinner from '../../components/Spinner.vue'

const intervalUnit = {
  years: 'Yearly',
  months: 'Monthly',
  weeks: 'Weekly',
  days: 'Daily',
  day: 'Daily',
}

export default {
  components: {
    PencilAltIcon,
    ArrowLeftIcon,
    SideView,
    ProcessingLoader,
    FetchProgressLoader,
    Spinner,
  },
  data() {
    return {
      open: false,
      planDelay: false,
      planEditDelay: false,
      page: 1,
      nextFetch: false,
      isNextFetch: false,
      loading: false,
      product_name: '...',
    }
  },
  methods: {
    openView(pluginId) {
      this.$store.dispatch('plugin/setPluginId', pluginId)
      this.planEditDelay = false
      this.open = true
    },
    updateDelay(status) {
      this.planEditDelay = status
    },
    close() {
      this.planEditDelay = false
      this.open = false
    },
    fetchPlugins() {
      let product_id = this.$route.params.id
      let page = this.page
      this.$store
        .dispatch('plugin/plugins', { product_id, page })
        .then(() => {
          if (this.plans && this.plans.length > 0) {
            this.product_name = this.plans[0].product_name
          } else {
            this.fetchProductName(product_id)
          }
        })
        .then(() => {
          this.planDelay = true
          this.nextFetch = true
          this.isNextFetch = false
        })
        .catch((err) => {
          const notification = {
            type: 'error',
            message: err,
          }
          this.$store.dispatch('notification/add', notification)
        })
    },
    fetchProductName(product_id) {
      this.$store.dispatch('product/product', product_id).then((res) => {
        if (res.data.data) {
          this.product_name = res.data.data.name
        }
      })
    },
    fetchMorePlugins() {
      if (this.nextFetch && this.$store.state.plugin.plugins.info.more_records && this.planDelay) {
        if (window.scrollY > 15) {
          this.isNextFetch = true
          this.page = this.page + 1
          this.fetchPlugins()
          this.nextFetch = false
        }
      }
    },
    syncPlan() {
      this.loading = true
      this.$store
        .dispatch('plugin/sync', {})
        .then((res) => {
          if (!res.data.has_error) {
            this.fetchPlugins()
          }
          const notification = {
            type: res.data.has_error ? 'error' : 'success',
            message: res.data.message,
          }
          this.$store.dispatch('notification/add', notification)
        })
        .catch((err) => {
          const notification = {
            type: 'error',
            message: err,
          }
          this.$store.dispatch('notification/add', notification)
        })
        .then(() => {
          this.loading = false
        })
    },
    capitalizeFirstLetter(param) {
      let status = param == 'inactive' ? 'inactive' : param
      return status.charAt(0).toUpperCase() + status.slice(1)
    },
    mappedIntervalUnit(param) {
      return intervalUnit[param]
    },
  },
  created() {
    this.fetchPlugins()
  },
  computed: {
    ...mapGetters('plugin', { plans: 'plugins', info: 'info', pluginId: 'getPluginId' }),
  },
}
</script>
