const processCard = (subscription = null) => {
  const subscriptionType = {
    ACTIVE: {
      theme: 'bg-red-700 text-white',
      textColor: 'text-red-700',
      btnText: 'Cancel Subscription',
      status: 'Active',
    },
    LIVE: {
      theme: 'bg-red-700 text-white',
      textColor: 'text-red-700',
      btnText: 'Cancel Subscription',
      status: 'Active',
    },
    TRIAL: {
      theme: 'bg-blue-700 text-white',
      textColor: 'text-blue-700',
      btnText: 'Upgrade Subscription',
      status: 'Trial',
    },
    TRIAL_EXPIRED: {
      theme: 'bg-blue-700 text-white',
      textColor: 'text-blue-700',
      btnText: 'Upgrade Subscription',
      status: 'Trial_Expired',
    },
    NON_RENEWING: {
      theme: 'bg-green-700 text-white',
      textColor: 'text-green-700',
      btnText: 'Reactive Subscription',
      status: 'Non_Renewing',
    },
    CANCELLED: {
      theme: 'bg-blue-700 text-white',
      textColor: 'text-blue-700',
      btnText: 'Upgrade Subscription',
      status: 'Cancelled',
    },
    EXPIRED: {
      theme: 'bg-green-700 text-white',
      textColor: 'text-green-700',
      btnText: 'Renew Subscription',
      status: 'Expired',
    },
  }
  let onFlyStatus = subscription.has_card && subscription.status == 'TRIAL' ? 'ACTIVE' : subscription.status
  let status = Object.prototype.hasOwnProperty.call(subscriptionType, onFlyStatus)
    ? subscriptionType[onFlyStatus]
    : subscriptionType['NON_RENEWING']

  let remainingDay = subscription.trial_remaining_days == null ? 0 : subscription.trial_remaining_days
  let card = {
    ...status,
    hasTrial: onFlyStatus == 'TRIAL' && remainingDay > 0, // || onFlyStatus == 'NON_RENEWING',
    trialPeriod:
      onFlyStatus == 'TRIAL' && remainingDay > 0 //|| onFlyStatus == 'NON_RENEWING'
        ? remainingDay + (remainingDay > 1 ? ' days ' : ' day ') + 'remaining'
        : '',
  }
  return card
}
const processStatus = (subscription = null) => {
  let remainingDay = subscription.trial_remaining_days == null ? 0 : subscription.trial_remaining_days
  let status = {
    trial:
      subscription.status == 'TRIAL' && remainingDay > 0
        ? remainingDay + (remainingDay > 1 ? ' days ' : ' day ') + 'remaining'
        : '',
    text: subscription.status == 'TRIAL' && subscription.has_card ? 'ACTIVE' : subscription.status,
    info:
      subscription.status == 'TRIAL' && subscription.has_card
        ? 'Your card will be charged after end the trial period'
        : '',
  }
  return status
}

export default {
  processCard,
  processStatus,
}
