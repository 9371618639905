<template>
  <div>
    <label v-if="label" class="block text-xs sm:text-sm font-normal text-gray-600">{{ label }}</label>
    <select
      class="mt-1 base-select"
      :value="modelValue"
      v-bind="{
        ...$attrs,
        onChange: ($event) => {
          $emit('update:modelValue', $event.target.value)
        },
      }"
    >
      <slot>
        <option v-for="option in options" :value="option.id" :key="option.id" :selected="option.name === modelValue">
          {{ option.name }}
        </option>
      </slot>
    </select>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    options: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
}
</script>
