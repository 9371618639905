<template>
  <div class="min-h-full flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8">
    <div class="mt-8 px-4 mx-auto w-full">
      <div class="flex flex-col items-center gap-6">
        <div class="sm:flex">
          <span class="text-xl sm:text-4xl text-red-800 font-bold sm:font-extrabold mr-2 sm:mr-6">403</span>
          <div class="flex flex-col">
            <span class="text-xl sm:text-4xl text-gray-700 font-bold sm:font-extrabold">Forbidden</span>
            <span class="mt-0.5 text-xs sm:text-sm font-normal text-gray-400"
              >You don't have enough permission to view this page</span
            >

            <router-link
              to="/"
              active-class="bg-gray-900 text-white"
              :class="['items-start pt-6 text-sm font-medium text-blue-400 underline']"
            >
              Go back home
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>
