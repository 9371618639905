<template>
  <div class="border border-gray-200 shadow px-4 py-6 w-48 sm:w-52 overflow-hidden bg-white rounded-md">
    <div class="flex flex-col items-center gap-2 sm:gap-6 h-full justify-between">
      <div class="flex flex-col items-center gap-4">
        <img :src="logo" alt="plugin logo" class="w-24 h-24 rounded-full" />
        <div class="flex flex-col gap-1 items-center">
          <p class="text-blue-500 text-xs font-bold text-center">{{ pluginName }}</p>
          <p :class="['inline-flex items-center text-xs font-extrabold text-center text-gray-500']">
            {{ formatStatus(status.text) }}
            <Tooltip v-if="status.info" :tooltip="message" class="ml-1 text-yellow-400" />
          </p>
          <p v-if="subscriptionCard.hasTrial" class="text-gray-500 text-xs font-medium text-center">
            {{ subscriptionCard.trialPeriod }}
          </p>
        </div>
      </div>
      <div class="space-y-2 w-full">
        <slot>
          <button
            :class="[
              'w-full border border-transparent bg-blue-500 text-white shadow text-xs px-2 py-1.5 font-normal rounded-full self-end',
            ]"
            @click="emitEvent"
          >
            Manage subscription
          </button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import processSub from '../../../helpers/processSubscription'
import Tooltip from '../../../components/CssTooltip.vue'

export default {
  components: {
    Tooltip,
  },
  props: {
    subscription: {
      type: Object,
      required: true,
    },
  },
  emits: ['open-sidebar'],
  data() {
    return {
      plan: this.subscription.plan ? this.subscription.plan : {},
      subscriptionCard: processSub.processCard(this.subscription),
      status: processSub.processStatus(this.subscription),
    }
  },
  methods: {
    emitEvent() {
      this.$emit('open-sidebar')
    },
    formatStatus(status) {
      return status.split('_').join(' ')
    },
  },
  computed: {
    logo() {
      return this.plan.logo ? this.plan.logo : '/plugin-logo.png'
    },
    pluginName() {
      return this.plan.name || ''
    },
    message() {
      return this.status.trial ? this.status.info + ' (' + this.status.trial + ') ' : this.status.info
    },
  },
}
</script>
