//import { computed } from 'vue'

export default (subscription = null) => {
  const subscriptionType = {
    ACTIVE: {
      theme: 'bg-red-700 text-white',
      textColor: 'text-red-700',
      btnText: 'Cancel Subscription',
      status: 'Active',
    },
    LIVE: {
      theme: 'bg-red-700 text-white',
      textColor: 'text-red-700',
      btnText: 'Cancel Subscription',
      status: 'Active',
    },
    TRIAL: {
      theme: 'bg-blue-700 text-white',
      textColor: 'text-blue-700',
      btnText: 'Upgrade Subscription',
      status: 'Trial',
    },
    TRIAL_EXPIRED: {
      theme: 'bg-blue-700 text-white',
      textColor: 'text-blue-700',
      btnText: 'Upgrade Subscription',
      status: 'Trial',
    },
    NON_RENEWING: {
      theme: 'bg-red-700 text-white',
      textColor: 'text-red-700',
      btnText: 'Reactive Subscription',
      status: 'Expired',
    },
    CANCELLED: {
      theme: 'bg-green-700 text-white',
      textColor: 'text-green-700',
      btnText: 'Renew Subscription',
      status: 'Cancelled',
    },
    EXPIRED: {
      theme: 'bg-green-700 text-white',
      textColor: 'text-green-700',
      btnText: 'Renew Subscription',
      status: 'Expired',
    },
  }

  let status = Object.prototype.hasOwnProperty.call(subscriptionType, subscription.status)
    ? subscriptionType[subscription.status]
    : subscriptionType['NON_RENEWING']

  return {
    ...status,
    hasTrial: subscription.status == 'TRIAL' && subscription.trial_remaining_days > 0,
    trialPeriod:
      subscription.status == 'TRIAL' && subscription.trial_remaining_days > 0
        ? subscription.trial_remaining_days + (subscription.trial_remaining_days > 1 ? ' days ' : ' day ') + 'remaining'
        : '',
  }
}
