<template>
  <App />
</template>

<script>
import App from './App.vue'
import { provideAuth0 } from './auth0/Authentication'

export default {
  name: 'Shell',
  components: { App },
  setup() {
    provideAuth0()
  },
}
</script>
