import { createStore } from 'vuex'
import * as user from './modules/user'
import * as product from './modules/product'
import * as plugin from './modules/plugin'
import * as setting from './modules/setting'
import * as subscription from './modules/subscription'
import * as dashboard from './modules/dashboard'
import * as notification from './modules/notification'

export default createStore({
  modules: {
    user,
    product,
    plugin,
    setting,
    subscription,
    dashboard,
    notification,
  },
})
