import http from './Http/Client'

export default {
  plugins(param) {
    return http.get(process.env.VUE_APP_API_PREFIX + 'plugin/plans', param)
  },
  nonSubscribePlugin(param) {
    return http.get(process.env.VUE_APP_API_PREFIX + 'plugin/not-subscribed', param)
  },
  categories(param) {
    return http.get(process.env.VUE_APP_API_PREFIX + 'plugin/categories', param)
  },
  pluginDetailsById(params) {
    return http.get(process.env.VUE_APP_API_PREFIX + 'plugin/plan/' + params.params.id)
  },
  pluginDetailsUpdate(params) {
    return http.post(process.env.VUE_APP_API_PREFIX + 'plugin/plan', params)
  },
  pluginSync() {
    return http.get(process.env.VUE_APP_API_PREFIX + 'plugin/getZSubscriptionPlans')
  },
}
