export const namespaced = true

export const state = {
  notifications: [],
}

let autoIncreament = 1

export const mutations = {
  PUSH(state, notification) {
    state.notifications.push({
      ...notification,
      id: autoIncreament++,
    })
  },
  DELETE(state, deleteNotification) {
    state.notifications = state.notifications.filter((notification) => notification.id !== deleteNotification.id)
  },
}

export const actions = {
  add({ commit }, notification) {
    commit('PUSH', notification)
  },
  remove({ commit }, deleteNotification) {
    commit('DELETE', deleteNotification)
  },
}

export const getters = {
  //
}
