<template>
  <div>
    <h2 class="text-gray-600 text-sm font-bold py-2">Recently Used Subscriptions</h2>
    <div class="bg-white py-8 px-8 rounded-md shadow">
      <div v-if="subscriptionDelay" class="mt-4 flex flex-row flex-wrap gap-4 sm:gap-6 justify-start">
        <Subscription
          v-for="subscription in subscriptions"
          :key="subscription.id"
          :subscription="subscription"
          class="hover:cursor-not-allowed"
        />
      </div>
      <ProcessingLoader v-else as="template" class="mt-4" />
    </div>
  </div>
</template>

<script>
import Subscription from '../shared/Subscription.vue'
import ProcessingLoader from '../dashboard/partials/DashboardPreloader.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    Subscription,
    ProcessingLoader,
  },
  data() {
    return {
      defaultStatus: 'Try_Now',
      subscriptionDelay: false,
    }
  },
  methods: {
    fetchSubscriptions() {
      let limit = 10
      this.$store
        .dispatch('dashboard/recentSubscriptionUsed', { limit })
        .then(() => {
          this.subscriptionDelay = true
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    this.fetchSubscriptions()
  },
  computed: {
    ...mapGetters('dashboard', { subscriptions: 'subscriptions', info: 'info' }),
  },
}
</script>
