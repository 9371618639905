import { isAuthenticated, isLoading, isVerifyEmail, isRegistered, login } from './Authentication'
import { watch } from 'vue'

export const authenticationGuard = (to, from, next) => {
  const guardAction = () => {
    if (isAuthenticated.value) {
      if (to.path !== '/verify-mail' && !isVerifyEmail.value) {
        return next({ name: 'verifyMail' })
      } else if (to.path === '/verify-mail' && isVerifyEmail.value) {
        return next('/')
      } else if (to.path !== '/registration' && !isRegistered.value) {
        return next({ name: 'completeRegistration' })
      } else if (to.path === '/registration' && isRegistered.value) {
        return next('/')
      } else {
        return next()
      }
    }

    login({
      appState: {
        targetUrl: to.fullPath,
      },
    })
  }

  if (!isLoading.value) {
    return guardAction()
  }

  watch(isLoading, (currentValue) => {
    if (!currentValue) {
      return guardAction()
    }
  })
}
