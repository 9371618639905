<template>
  <div class="bg-white py-8 px-8 rounded-md shadow">
    <div v-if="settingsDelay" class="px-4 md:px-6 mt-3 flex flex-row flex-wrap justify-center gap-2 md:justify-between">
      <div class="flex flex-col items-center justify-center"></div>
      <div class="flex flex-col items-center justify-center">
        <div
          :class="`bg-` + this.msgColor + `-100 border-` + this.msgColor + `-500 text-` + this.msgColor + `-900`"
          class="border-t-4 rounded-b px-4 py-3 shadow-md"
          role="alert"
        >
          <div class="flex">
            <div class="py-1">
              <svg
                :class="`text-` + this.msgColor + `-500`"
                class="fill-current h-6 w-6 mr-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
                />
              </svg>
            </div>
            <div v-if="success" class="success">
              <div v-if="this.actionStatus == 'upgrade'" class="success">
                <div class="px-4 md:px-6 flex flex-col flex-wrap justify-center gap-2 md:justify-between">
                  <label class="text-light text-sm font-medium text-gray-700 mb-1 pb-1">
                    Congratulations! Your subscription is to <span class="font-bold">{{ name }}</span> now active.
                  </label>
                  <label class="text-light text-sm font-medium text-gray-700 mb-1 pb-1">
                    Please note, we will continue to charge your current payment method monthly.
                  </label>
                  <label class="text-light text-sm font-medium text-gray-700 mb-1 pb-1">
                    If you wish to update your payment information:
                  </label>
                  <label class="text-light text-sm font-medium text-gray-700 mb-1 pb-1">
                    Please go to the <a class="text-blue-700 underline" href="/billings">Billing</a> tab.
                  </label>
                  <label class="text-light text-sm font-medium text-gray-700 mb-1 pb-1">
                    An email containing the bill has been sent to your email address
                  </label>
                  <p class="text-sm">
                    Go to
                    <a href="/subscriptions">
                      <button
                        class="
                          w-32
                          border border-transparent
                          bg-blue-500
                          text-white
                          shadow
                          text-xs
                          px-2
                          py-1.5
                          font-normal
                          rounded-full
                          self-end
                          ml-4
                        "
                      >
                        My Subscriptions
                      </button>
                    </a>
                  </p>
                </div>
              </div>
              <div v-if="this.actionStatus == 'renew'" class="success">
                <div class="px-4 md:px-6 flex flex-col flex-wrap justify-center gap-2 md:justify-between">
                  <label class="text-light text-sm font-medium text-gray-700 mb-1 pb-1">
                    Congratulations! Your subscription is to <span class="font-bold">{{ name }}</span> has been
                    successfully renewed.
                  </label>
                  <label class="text-light text-sm font-medium text-gray-700 mb-1 pb-1">
                    Please note, we will continue to charge your current payment method monthly.
                  </label>
                  <label class="text-light text-sm font-medium text-gray-700 mb-1 pb-1">
                    If you wish to update your payment information:
                  </label>
                  <label class="text-light text-sm font-medium text-gray-700 mb-1 pb-1">
                    Please go to the <a class="text-blue-700 underline" href="/billings">Billing</a> tab.
                  </label>
                  <label class="text-light text-sm font-medium text-gray-700 mb-1 pb-1">
                    An email containing the bill has been sent to your email address
                  </label>
                  <p class="text-sm">
                    Go to
                    <a href="/subscriptions">
                      <button
                        class="
                          w-32
                          border border-transparent
                          bg-blue-500
                          text-white
                          shadow
                          text-xs
                          px-2
                          py-1.5
                          font-normal
                          rounded-full
                          self-end
                          ml-4
                        "
                      >
                        My Subscriptions
                      </button>
                    </a>
                  </p>
                </div>
              </div>
              <div v-if="this.actionStatus == 'update_card'" class="success">
                <p class="font-bold mb-3">
                  <span>{{ this.message }}</span>
                </p>
                <p class="text-sm">
                  Go to
                  <a href="/subscriptions">
                    <button
                      class="
                        w-32
                        border border-transparent
                        bg-blue-500
                        text-white
                        shadow
                        text-xs
                        px-2
                        py-1.5
                        font-normal
                        rounded-full
                        self-end
                        ml-4
                      "
                    >
                      My Subscriptions
                    </button>
                  </a>
                </p>
              </div>
            </div>
            <div v-else class="error">
              <p class="font-bold mb-3">
                <span>{{ this.message }}</span>
              </p>
              <p class="text-sm">
                Go to
                <a href="/subscriptions">
                  <button
                    class="
                      w-32
                      border border-transparent
                      bg-blue-500
                      text-white
                      shadow
                      text-xs
                      px-2
                      py-1.5
                      font-normal
                      rounded-full
                      self-end
                      ml-4
                    "
                  >
                    My Subscriptions
                  </button>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col items-center justify-center"></div>
    </div>
    <ProcessingLoader v-else as="template" class="mt-4" />
  </div>
</template>

<script>
import ProcessingLoader from '../../components/ProcessingLoader.vue'
export default {
  components: {
    ProcessingLoader,
  },
  data() {
    return {
      settingsDelay: false,
      success: false,
      message: null,
      msgColor: null,
      name: null,
      actionStatus: null,
    }
  },
  created() {
    this.getConfirmation()
  },
  methods: {
    getConfirmation() {
      let hostedpage_id = this.$route.query.hostedpage_id
      this.$store
        .dispatch('subscription/confirm', { hostedpage_id })
        .then(({ data }) => {
          this.actionStatus = data.action_status
          this.success = true
          this.message = data.message
          this.msgColor = 'green'
          this.name = data.plan_name
        })
        .catch((err) => {
          this.success = false
          this.message = err.message
          this.msgColor = 'red'
        })
        .finally(() => (this.settingsDelay = true))
    },
  },
}
</script>
