<template>
  <!-- Checkout options -->
  <div v-if="checkout" class="-mt-1 px-4 md:px-6 flex flex-col flex-wrap justify-center gap-2 md:justify-between">
    <div>
      <span class="text-sm font-medium text-gray-600">Manage Subscription</span>
      <Divider>
        <span class="text-sm font-semibold text-gray-600">Subscription Details</span>
      </Divider>
    </div>
    <div class="my-6 px-4 flex flex-row flex-wrap justify-center gap-2 md:justify-between">
      <div class="flex flex-col items-center gap-2 md:gap-3 justify-center">
        <label class="font-medium text-gray-600 text-xs">Renew date</label>
        <label class="font-semibold text-gray-700 text-md">{{ renewDate }}</label>
      </div>
      <div class="flex flex-col items-center gap-2 md:gap-3 justify-center">
        <label class="font-medium text-gray-600 text-xs">Licensed Users</label>
        <label class="font-semibold text-gray-700 text-md">1</label>
      </div>
      <div class="flex flex-col items-center gap-2 md:gap-3 justify-center">
        <label class="font-medium text-gray-600 text-xs">Total</label>
        <label class="font-semibold text-gray-700 text-md">${{ price }}</label>
      </div>
    </div>

    <div class="flex flex-row items-end justify-end">
      <div class="w-28 space-y-4">
        <button
          @click="cancelCheckout"
          class="
            w-full
            inline-flex
            items-center
            justify-center
            border border-transparent
            bg-red-600
            text-white text-xs
            px-2
            py-1.5
            font-semibold
            rounded-full
            shadow
          "
        >
          Cancel
        </button>
      </div>
      <div class="w-44 space-y-0 ml-2">
        <label v-if="true" class="font-medium text-xs text-blue-500 italic">Billed {{ mappedIntervalUnit }}</label>
        <button
          class="
            w-full
            inline-flex
            items-center
            justify-center
            bg-blue-600
            text-white
            border border-transparent
            text-xs
            px-2
            py-1.5
            font-semibold
            rounded-full
            shadow
          "
          @click="checkoutConfirmation"
          :disabled="loading"
        >
          <slot>
            <Spinner v-if="loading" :size="4" :color="'text-white'" />
            <span class="ml-2">Proceed to checkout</span>
          </slot>
        </button>
      </div>
    </div>
  </div>
  <!-- Default open view -->
  <div v-else class="px-4 md:px-6 flex flex-row flex-wrap justify-center gap-2 md:justify-between">
    <div class="flex flex-col gap-2 md:gap-4 items-center">
      <img :src="logo" class="h-24 w-24 border-2 border-gray-200 rounded-full" alt="" />
      <div class="flex flex-col gap-1 items-center">
        <p class="text-gray-800 text-xs font-semibold text-center">{{ pluginName }}</p>
        <p :class="['inline-flex items-center text-xs font-extrabold text-center text-gray-500']">
          {{ formatStatus(status.text) }}
          <Tooltip v-if="status.info" :tooltip="status.info" class="ml-1 text-yellow-400" />
        </p>
        <p v-if="card.hasTrial" class="text-gray-500 text-xs font-medium text-center">
          {{ card.trialPeriod }}
        </p>
      </div>
    </div>
    <div class="flex flex-col items-center justify-center">
      <div class="w-40 space-y-4">
        <button
          :class="[
            card.theme,
            'w-full border border-transparent shadow-md text-xs px-2 py-1.5 font-medium rounded-full',
          ]"
          @click="proceedToCheckout"
        >
          {{ card.btnText }}
        </button>
        <button
          v-if="cancelUpgrade"
          :class="[
            'bg-red-700 text-white w-full border border-transparent shadow-md text-xs px-2 py-1.5 font-medium rounded-full',
          ]"
          @click="cancelUpgradeConfirmation"
          :disabled="loading"
        >
          Cancel Subscription
        </button>
        <a :href="pluginUrl" target="_blank">
          <button
            :class="[
              'w-full mt-3 border border-transparent shadow-md bg-blue-400 text-white text-xs px-2 py-1.5 font-medium rounded-full',
            ]"
          >
            Launch Plugin
          </button>
        </a>
      </div>
    </div>
  </div>
  <ConfirmationModal :show="consent" @close="close">
    <template #title>
      <h2 class="text-md text-gray-600 font-medium" v-if="confirmText.title">{{ confirmText.title }}</h2>
    </template>
    <template v-slot:body>
      <p class="text-sm text-gray-700" v-if="confirmText.body" v-html="replaceVariable(confirmText.body)"></p>
    </template>
    <ConfirmButton :loading="loading" @click="confirmedToCheckout" class="bg-blue-600">
      <template v-if="confirmText.button">{{ confirmText.button }}</template>
    </ConfirmButton>
  </ConfirmationModal>
</template>

<script>
import Divider from '../../../components/Divider.vue'
import ConfirmationModal from '../../../components/ConfirmationModal.vue'
import ConfirmButton from '../../../components/ConfirmationButton.vue'
import processSub from '../../../helpers/processSubscription'
import Spinner from '../../../components/Spinner.vue'
import Tooltip from '../../../components/CssTooltip.vue'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import duration from 'dayjs/plugin/duration'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.extend(isSameOrBefore)
const intervalUnit = {
  years: 'Yearly',
  months: 'Monthly',
  weeks: 'Weekly',
  days: 'Daily',
  day: 'Daily',
}

import { mapActions } from 'vuex'

const confirmMessage = {
  TRIAL: {
    title: ``,
    body: `To upgrade your trial, we will required a payment method.
          Please click button below to be redirected to ad a payment method.`,
    button: `Proceed`,
  },
  ACTIVE: {
    title: `We're sorry to see you go!`,
    body: `<p class="text-sm text-gray-700 font-normal  leading-6">Your monthly subscription is paid until {date_year}. If you would like to proceed with cancelling your current subscription, please select <strong>Cancel Subscription</strong> below.</p>
          <p class="mt-4 text-sm text-gray-700  leading-6"><strong>(!)</strong> Are you sure? After {date_year}, you will still be able to access the plugin but will not be able to use any of the available features.
          If you have any questions or concerns you can contact us at <strong>help@saasplugin.com.au</strong></p>`,
    button: `Cancel Subscription`,
  },
  LIVE: {
    title: `We're sorry to see you go!`,
    body: `<p class="text-sm text-gray-700 font-normal  leading-6">Your monthly subscription is paid until {date_year}. If you would like to proceed with cancelling your current subscription, please select <strong>Cancel Subscription</strong> below.</p>
          <p class="mt-4 text-sm text-gray-700  leading-6"><strong>(!)</strong> Are you sure? After {date_year}, you will still be able to access the plugin but will not be able to use any of the available features.
          If you have any questions or concerns you can contact us at <strong>help@saasplugin.com.au</strong></p>`,
    button: `Cancel Subscription`,
  },
  TRIAL_EXPIRED: {
    title: `Welcome back!`,
    body: `To upgrade your trial, we will required a payment method. Please click button below to be redirected to ad a payment method.`,
    button: `Add payment`,
  },
  CANCELLED: {
    title: `Welcome back!`,
    body: `Are you sure to renew your subscription?`,
    button: `Renew Subscription`,
  },
  CANCEL_TRIAL: {
    title: `We're sorry to see you go!`,
    body: `<p class="text-sm text-gray-700 font-normal  leading-6">You still have {days_left} day/s left on your Trial. If you would like to proceed with cancelling your Trial, please select <strong>Cancel Trial</strong> below.</p>
           <p class="mt-4 text-sm text-gray-700  leading-6"><strong>(!)</strong> Are you sure? After {date_year}, you will still be able to access the plugin but will not be able to use any of the available features.
                If you have any questions or concerns you can contact us at <strong>help@saasplugin.com.au</strong></p>`,
    button: `Cancel Trial`,
  },
}

export default {
  components: {
    Divider,
    ConfirmationModal,
    Tooltip,
    ConfirmButton,
    Spinner,
  },
  props: {
    subscription: Object,
  },
  emits: ['close'],
  data() {
    return {
      plan: this.subscription.plan ? this.subscription.plan : {},
      card: processSub.processCard(this.subscription),
      status: processSub.processStatus(this.subscription),
      checkout: false,
      consent: false,
      cancelTrial: false,
      confirmText: null,
      loading: false,
    }
  },
  methods: {
    ...mapActions('subscription', [
      'upgradeSubscription',
      'reactiveSubscription',
      'renewSubscription',
      'cancelSubscription',
    ]),
    proceedToCheckout() {
      let who = this.card.status.toUpperCase()
      this.cancelTrial = false
      if (who == 'ACTIVE' || who == 'LIVE') {
        this.confirmText = confirmMessage[who]
        this.consent = true
      } else {
        this.checkout = true
      }
    },
    cancelCheckout() {
      this.checkout = false
    },
    checkoutConfirmation() {
      let who = this.card.status.toUpperCase()
      this.confirmText = confirmMessage[who]
      //stop open confirm modal for this status
      if (who == 'NON_RENEWING' || who == 'CANCELLED' || who == 'EXPIRED' || who == 'TRIAL' || who == 'TRIAL_EXPIRED') {
        this.confirmedToCheckout()
      } else {
        this.consent = true
      }
    },
    cancelUpgradeConfirmation() {
      this.cancelTrial = true
      this.consent = true
      this.confirmText = confirmMessage['CANCEL_TRIAL']
    },
    async confirmedToCheckout() {
      this.loading = true
      let who = this.card.status.toUpperCase()
      if (who == 'NON_RENEWING') {
        await this.reactive()
      }
      if (!this.cancelTrial && (who == 'TRIAL' || who == 'TRIAL_EXPIRED' || who == 'CANCELLED')) {
        await this.upgrade()
      }
      if (who == 'EXPIRED') {
        await this.renew()
      }
      if (this.cancelTrial || who == 'ACTIVE' || who == 'LIVE') {
        await this.cancel()
      }
      this.$emit('close')
      this.loading = false
    },
    async upgrade() {
      await this.upgradeSubscription(this.subscription.id)
        .then(({ data }) => {
          window.location.href = data.url
        })
        .catch((err) => {
          let data = err.response ? err.response.data : {}
          let message = data.message || err.message
          this.notification(message)
        })
        .then(() => this.close())
    },
    async reactive() {
      await this.reactiveSubscription(this.subscription.id)
        .then((response) => {
          this.notification(response.message, 'success')
        })
        .catch((err) => {
          let data = err.response ? err.response.data : {}
          let message = data.message || err.message
          this.notification(message)
        })
        .then(() => this.close())
    },
    async renew() {
      await this.renewSubscription(this.subscription.id)
        .then(({ data }) => {
          window.location.href = data.url
        })
        .catch((err) => {
          let data = err.response ? err.response.data : {}
          let message = data.message || err.message
          this.notification(message)
        })
        .then(() => this.close())
    },
    async cancel() {
      await this.cancelSubscription(this.subscription.id)
        .then((response) => {
          this.notification(response.message, 'success')
        })
        .catch((err) => {
          let data = err.response ? err.response.data : {}
          let message = data.message || err.message
          this.notification(message)
        })
        .then(() => this.close())
    },
    notification(message, type = 'error') {
      const notification = {
        type: type,
        message: message,
      }
      this.$store.dispatch('notification/add', notification, { root: true })
    },
    close() {
      this.consent = false
    },
    replaceVariable(body) {
      let text = body.split('{days_left}').join(this.subscription.trial_remaining_days)
      let date = this.subscription.current_term_ends_at
        ? dayjs(this.subscription.current_term_ends_at).format('MMM DD, YYYY')
        : dayjs().format('MMM DD, YYYY')
      return text.split('{date_year}').join(date)
    },
    formatStatus(status) {
      return status.split('_').join(' ')
    },
  },
  computed: {
    logo() {
      return this.plan.logo ? this.plan.logo : 'plugin-logo.png'
    },
    pluginName() {
      return this.plan.name || ''
    },
    pluginUrl() {
      if (this.plan.plugin_url == '' || this.plan.plugin_url == null) {
        return 'https://oscillosoft.com.au'
      } else {
        return this.plan.plugin_url.includes('https://') ? this.plan.plugin_url : 'https://' + this.plan.plugin_url
      }
    },
    price() {
      return this.plan.plan_price || ''
    },
    renewDate() {
      let date = this.subscription.current_term_ends_at
        ? dayjs(this.subscription.current_term_ends_at).format('MMM DD, YYYY')
        : dayjs().format('MMM DD, YYYY')
      return date
    },
    cancelUpgrade() {
      return this.card.status == 'Trial' && !this.subscription.has_card
    },
    mappedIntervalUnit() {
      return intervalUnit[this.subscription.plan.interval_unit]
    },
  },
}
</script>
