<template>
  <h2 class="text-gray-600 text-md font-semibold pb-4">Manage profile</h2>
  <div class="bg-white py-8 px-8 rounded-md shadow">
    <div v-if="settingsDelay" class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      <div class="col-span-1 md:col-span-1">
        <div class="flex flex-col gap-4">
          <div class="">
            <img
              src="https://www.gravatar.com/avatar/HASH?d=mp"
              alt=""
              class="w-28 rounded-full border-2 border-gray-100"
            />
          </div>
          <div>
            <div class="">
              <span class="text-md text-gray-600 font-semibold">{{ userName }}</span>
              <span class="pl-1 text-xxs font-semibold text-blue-500 italic">(Account Owner)</span>
            </div>
            <span class="text-sm text-gray-500 font-medium">{{ userEmail }}</span>
          </div>
          <div>
            <h2 class="text-sm text-gray-600 font-semibold">Company Name</h2>
            <span class="text-sm text-gray-500 font-medium italic">{{ userCompany }}</span>
          </div>
        </div>
      </div>
      <div v-if="!isEdit" class="col-span-1 md:col-span-2">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-8">
          <div class="col-span-1 flex flex-col gap-4 mt-16">
            <div class="flex flex-col gap-2">
              <div class="flex flex-row gap-1 justify-between">
                <h2 class="text-sm text-gray-600 font-semibold mb-2">First Name</h2>
                <span class="text-sm text-gray-500 font-medium italic ml-10">{{ userInfo.first_name || 'N/A' }}</span>
              </div>
              <div class="flex flex-row gap-1 justify-between">
                <h2 class="text-sm text-gray-600 font-semibold mb-2">Last Name</h2>
                <span class="text-sm text-gray-500 font-medium italic ml-10">{{ userInfo.last_name || 'N/A' }}</span>
              </div>
              <div class="flex flex-row gap-1 justify-between">
                <h2 class="text-sm text-gray-600 font-semibold mb-2">Phone</h2>
                <span class="text-sm text-gray-500 font-medium italic ml-10">{{ userInfo.phone || 'N/A' }}</span>
              </div>
              <div class="flex flex-row gap-1 justify-between">
                <h2 class="text-sm text-gray-600 font-semibold mb-2">Mobile</h2>
                <span class="text-sm text-gray-500 font-medium italic ml-10">{{ userInfo.mobile || 'N/A' }}</span>
              </div>
              <div class="flex flex-row gap-1 justify-between">
                <h2 class="text-sm text-gray-600 font-semibold mb-2">Country</h2>
                <span class="text-sm text-gray-500 font-medium italic ml-10">{{ userInfo.country || 'N/A' }}</span>
              </div>
              <div class="flex flex-row gap-1 justify-between">
                <h2 class="text-sm text-gray-600 font-semibold mb-2">Timezone</h2>
                <span class="text-sm text-gray-500 font-medium italic ml-10">{{ userInfo.timezone || 'N/A' }}</span>
              </div>
            </div>
          </div>
          <div class="col-span-1 flex flex-col gap-4 items-end">
            <button @click="edit" class="text-blue-600"><PencilAltIcon class="w-5 h-5" /></button>
          </div>
        </div>
      </div>
      <div v-else class="col-span-1 md:col-span-2">
        <form @submit.prevent="updateUser">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-8">
            <div class="col-span-1 flex flex-col gap-4">
              <div>
                <text-input
                  label="First name"
                  @keypress="isLetter"
                  v-model="form.first_name"
                  :maxLength="50"
                  placeholder="Jhon"
                  :class="[errors.first_name ? 'base-error' : '']"
                />
                <span class="text-xs text-red-400 font-normal italic" v-if="errors.first_name">
                  {{ errors.first_name }}
                </span>
              </div>
              <div>
                <text-input
                  label="Last name"
                  @keypress="isLetter"
                  v-model="form.last_name"
                  :maxLength="50"
                  placeholder="Doe"
                  :class="[errors.last_name ? 'base-error' : '']"
                />
                <span class="text-xs text-red-400 font-normal italic" v-if="errors.last_name">
                  {{ errors.last_name }}
                </span>
              </div>
            </div>
            <div class="col-span-1 flex flex-col gap-4">
              <div>
                <text-input
                  label="Company Name"
                  v-model="form.organization"
                  :maxLength="100"
                  placeholder="Ex: oscillosfot"
                  :class="[errors.organization ? 'base-error' : '']"
                />
                <span class="text-xs text-red-400 font-normal italic" v-if="errors.organization">
                  {{ errors.organization }}
                </span>
              </div>
              <div class="flex flex-row gap-2">
                <div class="flex flex-col gap-1">
                  <text-input
                    label="Phone"
                    @keypress="isNumeric"
                    v-model="form.phone"
                    :maxLength="14"
                    placeholder="Work number"
                    :class="[errors.phone ? 'base-error' : '']"
                  />
                  <span class="text-xs text-red-400 font-normal italic" v-if="errors.phone">
                    {{ errors.phone }}
                  </span>
                </div>
                <div class="flex flex-col gap-1">
                  <text-input
                    label="Mobile"
                    @keypress="isNumeric"
                    v-model="form.mobile"
                    :maxLength="14"
                    placeholder="Mobile number"
                    :class="[errors.mobile ? 'base-error' : '']"
                  />
                  <span class="text-xs text-red-400 font-normal italic" v-if="errors.mobile">
                    {{ errors.mobile }}
                  </span>
                </div>
              </div>
              <div class="flex flex-row gap-2">
                <base-select :options="countries" v-model="form.country_id" label="Country" />
                <base-select v-model="form.timezone" label="Timezone">
                  <option
                    v-for="option in timezoneList"
                    :value="option.tzCode"
                    :key="option.name"
                    :selected="option.tzCode == form.timezone"
                  >
                    {{ option.label }}
                  </option>
                </base-select>
              </div>
            </div>
          </div>
          <div class="flex flex-row justify-end mt-8">
            <button
              @click.prevent="editCancel"
              class="
                bg-purple-200
                py-1.5
                px-4
                border border-gray-300
                rounded-md
                shadow-sm
                text-sm
                font-medium
                text-purple-800
                hover:bg-gray-50
                mr-2
              "
            >
              Cancel
            </button>
            <submit-button type="submit" :disabled="loading">
              <Spinner class="mr-2" v-if="loading" :size="4" />
              <span>Save</span>
            </submit-button>
          </div>
        </form>
      </div>
    </div>
    <PreLoader v-else as="template" />
  </div>
  <div class="mt-12">
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-12">
      <div class="bg-white pb-8 pt-4 px-8 rounded-md shadow col-span-1 md:col-span-1">
        <h2 class="pt-2 text-gray-600 text-md font-medium">Manage password</h2>
        <p class="text-xs">
          <span class="text-red-400 mr-1">Note:</span>
          <span class="text-gray-600">Update password will invalidate your login session.</span>
        </p>
        <div class="pt-4 flex flex-col gap-4">
          <div>
            <text-input
              label="Current password"
              v-model="managePassword.oldPassword"
              placeholder="*******"
              type="password"
            />
            <span class="text-xs text-red-400 font-normal italic" v-if="errors.current_password">
              {{ errors.current_password }}
            </span>
          </div>
          <div>
            <text-input label="New password" v-model="managePassword.newPassword" placeholder="" type="password" />
            <span class="text-xs text-red-400 font-normal italic" v-if="errors.password">
              {{ errors.password }}
            </span>
          </div>
          <div>
            <text-input
              label="Confirm password"
              v-model="managePassword.confirmPassword"
              placeholder=""
              type="password"
            />
          </div>
        </div>
        <div class="flex flex-row justify-end mt-4">
          <submit-button @click="passwordUpdate" :disabled="loadingpass">
            <Spinner class="mr-2" v-if="loadingpass" :size="4" />
            <span>Update</span>
          </submit-button>
        </div>
      </div>
      <div class="bg-white pb-8 pt-4 px-8 rounded-md shadow col-span-1 hidden">
        <h2 class="pt-2 text-gray-600 text-md font-medium">Two factor authentication(2FA)</h2>
        <small class="block font-normal text-gray-500">To secure your account you may add two step verification.</small>
        <small :class="[underDev, 'text-red-400 italic']">Under development. Coming soon...</small>
        <div class="mt-4">
          <button
            class="inline-flex px-4 py-1 text-sm text-gray-50 bg-blue-400 border border-transparent rounded-md shadow"
            @click="checkConfirmation"
          >
            Enable 2FA
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PencilAltIcon } from '@heroicons/vue/outline'
import SubmitButton from '../../components/PrimaryButton.vue'
import TextInput from '../../components/BaseInput.vue'
import BaseSelect from '../../components/BaseSelect.vue'
import PreLoader from './partials/SettingsPreloader.vue'
import Spinner from '../../components/Spinner.vue'
import processErrors from '../../helpers/processErrors'
import { mapActions, mapState } from 'vuex'
import timezones from 'timezones-list'

export default {
  components: {
    TextInput,
    BaseSelect,
    SubmitButton,
    PreLoader,
    Spinner,
    PencilAltIcon,
  },
  data() {
    return {
      settingsDelay: false,
      isEdit: false,
      errors: {},
      loading: false,
      loadingpass: false,
      form: {},
      managePassword: this.initPasswordData(),
      underDev: 'hidden',
    }
  },
  created() {
    this.fetchCountries()
    this.fetchUserInfo()
  },
  methods: {
    ...mapActions('setting', ['getUserInfo', 'getCountries', 'update', 'updatePassword', 'qRCode']),
    initUserData() {
      return {
        id: this.userInfo.id,
        first_name: this.userInfo.first_name,
        last_name: this.userInfo.last_name,
        organization: this.userInfo.company_name,
        phone: this.userInfo.phone,
        mobile: this.userInfo.mobile,
        country_id: this.userInfo.country_id,
        timezone: this.userInfo.timezone,
      }
    },
    initPasswordData() {
      return {
        oldPassword: null,
        newPassword: null,
        confirmPassword: null,
      }
    },
    fetchUserInfo() {
      this.getUserInfo({})
        .catch((err) => {
          const notification = {
            type: 'error',
            message: err.message,
          }
          this.$store.dispatch('notification/add', notification, { root: true })
        })
        .finally(() => {
          this.settingsDelay = true
        })
    },
    fetchCountries() {
      this.getCountries({}).catch((err) => {
        const notification = {
          type: 'error',
          message: err.message,
        }
        this.$store.dispatch('notification/add', notification, { root: true })
      })
    },
    updateUser() {
      this.resetErrors()
      this.hasFirstName()
      this.hasLastName()
      this.hasOrganization()
      if (!this.errors.first_name && !this.errors.last_name && !this.errors.organization) {
        this.loading = true
        this.update(this.form)
          .then((res) => {
            const notification = {
              type: res.data.has_error ? 'error' : 'success',
              message: res.data.message,
            }
            this.$store.dispatch('notification/add', notification, { root: true })
            this.isEdit = false
          })
          .catch((errors) => {
            if (errors.errors !== undefined) {
              this.errors = processErrors(errors.errors)
            } else {
              const notification = {
                type: 'error',
                message: errors.message,
              }
              this.$store.dispatch('notification/add', notification, { root: true })
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    edit() {
      this.resetErrors()
      this.form = this.initUserData()
      this.isEdit = true
    },
    editCancel() {
      this.isEdit = false
    },
    resetErrors() {
      this.errors = {}
    },
    isNumeric($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault()
      }
    },
    isLetter($event) {
      let char = String.fromCharCode($event.keyCode)
      if (/^[A-Za-z. ]+$/.test(char)) return true
      else $event.preventDefault()
    },
    hasLastName() {
      !this.form.last_name ? (this.errors['last_name'] = 'Last name is required') : ''
    },
    hasFirstName() {
      !this.form.first_name ? (this.errors['first_name'] = 'First name is required') : ''
    },
    hasOrganization() {
      !this.form.organization ? (this.errors['organization'] = 'Organization name is required') : ''
    },
    passwordUpdate() {
      if (!this.managePassword.oldPassword) {
        this.errors['current_password'] = 'The current password field is required.'
        return
      }

      if (!this.managePassword.newPassword) {
        this.errors['password'] = 'The password field is required.'
        return
      }

      if (this.managePassword.newPassword !== this.managePassword.confirmPassword) {
        this.errors['password'] = 'The password confirmation does not match.'
        return
      }

      this.loadingpass = true
      this.updatePassword({
        current_password: this.managePassword.oldPassword,
        password: this.managePassword.newPassword,
        password_confirmation: this.managePassword.confirmPassword,
      })
        .then(() => {
          this.managePassword = this.initPasswordData()
          this.errors = {}
          const notification = {
            type: 'success',
            message: 'Password updated successfully',
          }
          this.$store.dispatch('notification/add', notification, { root: true })
        })
        .catch(({ response }) => {
          this.errors = processErrors(response.data.errors || {})
        })
        .finally(() => (this.loadingpass = false))
    },
    checkConfirmation() {
      this.underDev = 'block'
      console.log('Development in progress')
      // this.checkConfirmation()
      //   .then((response) => {
      //     console.log(response)
      //   })
      //   .catch((error) => {
      //     console.log(error)
      //   })
    },
    showQR() {
      this.qRCode()
    },
  },
  computed: {
    ...mapState('setting', ['userInfo', 'countries']),
    userName() {
      return [this.userInfo.first_name, this.userInfo.last_name].join(' ')
    },
    userEmail() {
      return this.userInfo.email
    },
    userCompany() {
      return this.userInfo.company_name
    },
    timezoneList() {
      return timezones
    },
  },
  watch: {
    'managePassword.oldPassword': {
      handler: function () {
        delete this.errors['current_password']
      },
    },
    'managePassword.newPassword': {
      handler: function () {
        delete this.errors['password']
      },
    },
    'managePassword.confirmPassword': {
      handler: function () {
        delete this.errors['password']
      },
    },
  },
}
</script>
