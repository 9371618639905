import SettingService from '../../services/SettingService'

export const namespaced = true

export const state = {
  userInfo: {},
  billings: [],
  summary: {},
  countries: [],
}

export const mutations = {
  SET_USER_INFO_DETAILS(state, userData) {
    state.userInfo = userData
  },
  SET_USER_BILLING_INFO(state, billingData) {
    state.billings = billingData
  },
  SET_SUMMARY(state, summaryData) {
    state.summary = summaryData
  },
  SET_COUNTRIES(state, countries) {
    state.countries = countries
  },
  UPDATE_USER_BY_KEY(state, data) {
    let user = { ...state.userInfo }
    for (let key in data) {
      user[key] = data[key]
    }
    state.userInfo = user
  },
}

export const actions = {
  getUserInfo({ commit }) {
    return SettingService.getUsersInfo({}).then(({ data }) => {
      commit('SET_USER_INFO_DETAILS', data.data)
    })
  },
  getBillingInfo({ commit }) {
    return SettingService.getBillingInfo({}).then(({ data }) => {
      commit('SET_USER_BILLING_INFO', data)
    })
  },
  getSummary({ commit }) {
    return SettingService.getSummary({}).then(({ data }) => {
      commit('SET_SUMMARY', data)
    })
  },
  async update({ commit }, params) {
    return await SettingService.userDetailsUpdate(params).then((data) => {
      let response = data
      if (response.has_error) {
        return Promise.reject(response)
      }
      let user = { ...params }
      user['company_name'] = user['organization']
      user['country'] = state.countries.find((item) => item.id == user.country_id)['name']
      commit('UPDATE_USER_BY_KEY', user)
      return Promise.resolve(response)
    })
  },
  async updatePaymentMethod(_, id) {
    return await SettingService.updatePaymentMethod({
      subscription_id: id,
    }).then(({ data }) => {
      if (data.has_error) {
        return Promise.reject(data)
      }
      return Promise.resolve(data)
    })
  },
  async manageCategory(_, params) {
    return await SettingService.manageCategory(params).then(({ data }) => {
      if (data.has_error) {
        return Promise.reject(data)
      }
      return Promise.resolve(data)
    })
  },
  async getCountries({ commit }) {
    if (state.countries.length == 0) {
      return await SettingService.countries().then(({ data }) => {
        commit('SET_COUNTRIES', [{ id: '', name: 'Select a country' }].concat(data.data))
      })
    }
  },
  async updatePassword(_, params) {
    return await SettingService.updatePassword(params)
  },
  async qRCode() {
    return await SettingService.showQR()
  },
}

export const getters = {
  getUserInfo(state) {
    return state.userInfo
  },
  summary(state) {
    return state.summary && state.summary.data
  },
  billings(state) {
    return state.billings && state.billings.data
  },
  info() {
    return state.billings && state.billings.info
  },
}
