<template>
  <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
    <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
    <transition
      enter-active-class="transform ease-out duration-300 transition"
      enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
      leave-active-class="transition ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        class="
          max-w-sm
          w-full
          bg-white
          shadow-lg
          rounded-lg
          pointer-events-auto
          ring-1 ring-black ring-opacity-5
          overflow-hidden
        "
      >
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <component :is="notificationIcon" :class="[notificationType, 'h-6 w-6']" aria-hidden="true" />
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p :class="['text-sm font-semibold text-gray-700']">{{ notificationTitle }}</p>
              <p class="mt-1 text-sm text-gray-500">{{ notification.message }}</p>
            </div>
            <div class="ml-4 flex-shrink-0 flex">
              <button
                @click="deleteMe"
                class="
                  bg-white
                  rounded-md
                  inline-flex
                  text-gray-400
                  hover:text-gray-500
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                "
              >
                <span class="sr-only">Close</span>
                <XIcon class="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { CheckCircleIcon, ExclamationIcon } from '@heroicons/vue/outline'
import { XIcon } from '@heroicons/vue/solid'
import { mapActions } from 'vuex'

export default {
  components: {
    CheckCircleIcon,
    ExclamationIcon,
    XIcon,
  },
  props: {
    notification: {
      type: Object,
    },
  },
  data() {
    return {
      timeout: null,
    }
  },
  methods: {
    ...mapActions('notification', ['remove']),
    deleteMe() {
      this.remove(this.notification)
    },
  },
  computed: {
    notificationType() {
      return this.notification.type == 'success' ? 'text-green-400' : 'text-red-400'
    },
    notificationIcon() {
      return this.notification.type == 'success' ? 'CheckCircleIcon' : 'ExclamationIcon'
    },
    notificationTitle() {
      return this.notification.type == 'success' ? 'Success' : 'Error!'
    },
  },
  mounted() {
    this.timeout = setTimeout(() => this.remove(this.notification), 10000)
  },
  beforeUnmount() {
    clearTimeout(this.timeout)
  },
}
</script>
