<template>
  <div class="animate-pulse">
    <div class="mt-4 flex flex-row flex-wrap gap-4 sm:gap-6 sm:justify-start">
      <div
        v-for="item in [1, 2, 3, 4, 5]"
        :key="item"
        class="border border-gray-200 shadow px-4 pt-6 pb-4 w-36 sm:w-44 overflow-hidden bg-white rounded-md"
      >
        <div class="flex flex-col items-center gap-2 sm:gap-4 h-full justify-between">
          <div class="w-24 h-24 rounded-full bg-gray-200"></div>
          <div class="w-2/3 flex flex-col gap-1">
            <div class="w-full h-4 text-blue-500 text-xs font-bold text-center bg-gray-200 rounded-md"></div>
          </div>
          <div class="flex flex-col items-center w-full gap-1.5">
            <slot>
              <button
                class="
                  w-full
                  h-7
                  border border-transparent
                  bg-yellow-100
                  text-white
                  shadow
                  text-xs
                  px-2
                  py-1.5
                  font-normal
                  rounded-full
                  self-end
                "
              ></button>
            </slot>
            <label class="w-3/4 h-3 mt-1 text-blue-500 text-xs font-bold text-center bg-gray-200 rounded-md"> </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
