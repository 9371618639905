<template>
  <div :class="['inline-flex  has-tooltip hover:cursor-pointer']">
    <p :class="[area, distance, 'bg-gray-800 tooltip shadow rounded-md text-xs font-medium text-gray-50 px-2 py-1.5']">
      {{ tooltip }}
    </p>
    <slot>
      <InformationCircleIcon :class="['w-4 h-4']" />
    </slot>
  </div>
</template>

<script>
import { InformationCircleIcon } from '@heroicons/vue/solid'

export default {
  components: {
    InformationCircleIcon,
  },
  props: {
    tooltip: String,
    area: String,
    distance: { default: '-mt-8' },
  },
}
</script>
