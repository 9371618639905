<template>
  <TransitionRoot as="template" :show="show">
    <Dialog as="div" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0 bg-gray-50 opacity-50" />

        <div class="fixed mt-16 inset-y-0 pl-16 max-w-full right-0 flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-lg">
              <form
                class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl"
                @submit.prevent="tryNowEndPoint"
              >
                <div class="flex-1 h-0 overflow-y-auto">
                  <div class="pt-3 px-4 sm:px-4 flex items-start">
                    <div class="flex items-center">
                      <button
                        type="button"
                        class="
                          bg-gray-200
                          rounded-full
                          p-1
                          text-gray-500
                          hover:text-gray-600
                          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600
                        "
                        @click="$emit('close-sidebar')"
                      >
                        <span class="sr-only">Close panel</span>
                        <XIcon class="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div class="flex-1 flex flex-col justify-between">
                    <div class="divide-y divide-gray-200">
                      <div class="space-y-8 pt-4 pb-5 divide-y-2 divide-gray-200">
                        <div class="px-4 md:px-6 flex flex-row flex-wrap justify-center gap-2 md:justify-between">
                          <div class="flex flex-col gap-2 md:gap-4 items-center">
                            <img :src="logo" class="h-24 w-24 border-2 border-gray-200 rounded-full" alt="" />
                            <div class="flex flex-col gap-1">
                              <p class="text-purple-600 text-xs font-bold text-center">
                                {{ name }}
                              </p>
                            </div>
                          </div>
                          <div class="flex flex-col items-center justify-center">
                            <div class="w-40 space-y-4">
                              <button
                                class="
                                  text-white
                                  bg-green-600
                                  shadow-md
                                  border border-transparent
                                  text-xs
                                  px-2
                                  py-1
                                  font-semibold
                                  rounded-full
                                  w-full
                                  inline-flex
                                  items-center
                                  justify-center
                                "
                                type="submit"
                                :disabled="loading"
                              >
                                <Spinner v-if="loading" class="mr-1.5" :size="4" />
                                Start Trial
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-col px-6 py-2 gap-2">
                        <div class="flex flex-row gap-4">
                          <div class="flex flex-row gap-4">
                            <h2 class="text-sm font-medium text-gray-600">Subscription:</h2>
                            <p class="text-sm font-normal text-gray-500">Trial</p>
                          </div>
                        </div>
                        <div class="flex flex-row gap-4">
                          <div class="flex flex-row gap-4">
                            <h2 class="text-sm font-medium text-gray-600">Duration:</h2>
                            <p class="text-sm font-normal text-gray-500">{{ duration }}</p>
                          </div>
                        </div>
                        <!-- <div class="flex flex-row gap-4">
                          <div class="flex flex-row gap-4">
                            <h2 class="text-sm font-medium text-gray-600">Price:</h2>
                            <p class="text-sm font-normal text-gray-500">{{ price }}</p>
                          </div>
                        </div>
                        <div class="flex flex-row gap-4">
                          <div class="flex flex-row gap-4">
                            <h2 class="text-sm font-medium text-gray-600">Charged:</h2>
                            <p class="text-sm font-normal text-gray-500">{{ chargeInterval }}</p>
                          </div>
                        </div> -->
                        <div>
                          <h2 class="text-sm font-medium text-gray-600">Description:</h2>
                          <p class="mt-2 text-xs font-normal text-gray-500">{{ description }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <ConfirmModal :show="consent" :name="name" @confirm="confirmedToGoSubscribe" @close="close">
                <template v-slot:confirmText>
                  <p class="text-sm text-gray-500" v-if="confirmText">
                    {{ confirmText }}
                  </p>
                </template>
                <router-link
                  to="/subscriptions"
                  :class="[
                    `w-42 inline-flex items-center justify-center px-4 py-1.5 ml-2 text-sm font-medium text-white bg-blue-600
                    border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500`,
                  ]"
                >
                  Go to subscription
                </router-link>
              </ConfirmModal>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'
import ConfirmModal from '../../../components/ConfirmModal.vue'
import Spinner from '../../../components/Spinner.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    XIcon,
    ConfirmModal,
    Spinner,
  },
  props: {
    plugin: {
      type: Object,
      required: true,
    },
    show: Boolean,
  },
  emits: ['close-sidebar'],
  data() {
    return {
      timeout: null,
      confirmText: null,
      consent: false,
      loading: false,
    }
  },
  methods: {
    ...mapActions('plugin', ['tryNow']),
    tryNowEndPoint() {
      let params = { plan_id: this.plugin.id }
      this.loading = true
      this.tryNow(params)
        .then(() => {
          this.consent = true
        })
        .catch((err) => {
          let data = err.response ? err.response.data : {}
          let message = data.message || err.message
          this.triggerNotification({ type: 'error', message })
        })
        .then(() => {
          this.loading = false
        })
    },
    triggerNotification(notification) {
      this.$store.dispatch('notification/add', notification)
    },
    confirmedToGoSubscribe() {
      //this.$router.push({ name: 'subscriptions', replace: true })
      this.$router.push({ path: '/subscriptions' })
    },
    close() {
      this.consent = false
      this.$emit('close-sidebar')
    },
  },
  computed: {
    logo() {
      return this.plugin.logo ? this.plugin.logo : '/plugin-logo.png'
    },
    name() {
      return this.plugin.name || ''
    },
    description() {
      return this.plugin.description || 'N/A'
    },
    price() {
      return this.plugin.recurring_price ? '$' + this.plugin.recurring_price : 'N/A'
    },
    duration() {
      return this.plugin.trial_period ? this.plugin.trial_period + ' days' : 'N/A'
    },
    chargeInterval() {
      return this.plugin.interval_unit == 'months' ? 'Monthly' : 'Yearly'
    },
  },
  beforeUnmount() {
    clearTimeout(this.timeout)
  },
}
</script>
