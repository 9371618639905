export default {
  checkImage(url) {
    let file = null
    if (url) {
      file = this.fileUrlGenerate(url)
    } else {
      file = '/plugin-logo.png'
    }
    return file
  },
  fileUrlGenerate(url) {
    return process.env.VUE_APP_ASSET_URL + url
  },
}
