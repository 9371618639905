<template>
  <nav class="px-2 space-y-1">
    <router-link
      v-for="item in navigation"
      :key="item.name"
      :to="item.href"
      :class="[
        this.$route.path.indexOf(item.href) != -1
          ? 'bg-gray-900 text-white'
          : 'text-gray-300 hover:bg-gray-700 hover:text-white',
        'group flex items-center px-2 py-2 text-base font-medium rounded-md',
      ]"
    >
      <component
        :is="item.icon"
        :class="[
          item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
          'mr-4 flex-shrink-0 h-6 w-6',
        ]"
        aria-hidden="true"
      />
      {{ item.name }}
    </router-link>
  </nav>
</template>

<script>
export default {
  props: {
    navigation: {
      require: true,
    },
  },
}
</script>
