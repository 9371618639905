<template>
  <div class="bg-white py-6 px-4 sm:py-8 sm:px-8 rounded-md shadow">
    <div class="flex flex-wrap gap-2 justify-between items-center">
      <h2 class="text-sm text-gray-600 font-semibold">List of Products</h2>
      <button
        class="
          inline-flex
          items-center
          px-4
          py-1
          border
          bg-indigo-200
          border-indigo-200
          rounded-md
          text-indigo-600
          font-semibold
          text-sm
        "
        @click="syncProduct"
        :disabled="loading"
      >
        <Spinner v-if="loading" :size="4" :color="'text-blue-500'" />
        <span class="ml-2">Sync Products</span>
      </button>
    </div>
    <div class="mt-4 sm:mt-8">
      <div class="overflow-x-auto sm:rounded">
        <table class="min-w-full">
          <thead class="bg-gray-100">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Product Name
              </th>
              <th
                scope="col"
                class="px-6 py-3 w-3/4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Descriptions
              </th>
              <th
                scope="col"
                class="w-28 px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Plans
              </th>
            </tr>
          </thead>
          <tbody v-if="productDelay">
            <tr
              v-for="(product, index) in products"
              :key="product.id"
              :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
            >
              <td class="px-6 py-2 whitespace-nowrap text-sm font-medium text-blue-400">
                <router-link :to="{ name: 'product', params: { id: product.id } }">
                  {{ product.name }}
                </router-link>
              </td>
              <td class="px-6 py-2 w-3/4 text-xs text-gray-500">
                {{ product.description }}
              </td>
              <td class="w-28 px-6 py-2 whitespace-nowrap text-sm text-center text-gray-500">
                {{ product.plans_count }}
              </td>
            </tr>
            <tr v-if="isNextFetch">
              <td colspan="4">
                <FetchProgressLoader as="template" />
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="4">
                <ProcessingLoader as="template" class="mt-4" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
//import { PencilAltIcon } from '@heroicons/vue/outline'
import { mapGetters } from 'vuex'
import ProcessingLoader from '../../components/ProcessingLoader.vue'
import FetchProgressLoader from '../../components/FetchProgressLoader.vue'
import Spinner from '../../components/Spinner.vue'

export default {
  components: {
    ProcessingLoader,
    FetchProgressLoader,
    Spinner,
  },
  data() {
    return {
      productDelay: false,
      page: 1,
      nextFetch: false,
      isNextFetch: false,
      loading: false,
    }
  },
  methods: {
    fetchProducts() {
      let page = this.page
      this.$store
        .dispatch('product/products', { page })
        .then(() => {
          this.productDelay = true
          this.nextFetch = true
          this.isNextFetch = false
        })
        .catch((err) => {
          const notification = {
            type: 'error',
            message: err,
          }
          this.$store.dispatch('notification/add', notification)
        })
    },
    fetchMoreProducts() {
      if (this.nextFetch && this.$store.state.product.products.info.more_records && this.productDelay) {
        if (window.scrollY > 150) {
          this.isNextFetch = true
          this.page = this.page + 1
          this.fetchProducts()
          this.nextFetch = false
        }
      }
    },
    syncProduct() {
      this.loading = true
      this.$store
        .dispatch('product/sync', {})
        .then((res) => {
          if (!res.data.has_error) {
            this.fetchProducts()
          }
          const notification = {
            type: res.data.has_error ? 'error' : 'success',
            message: res.data.message,
          }
          this.$store.dispatch('notification/add', notification)
        })
        .catch((err) => {
          const notification = {
            type: 'error',
            message: err,
          }
          this.$store.dispatch('notification/add', notification)
        })
        .then(() => {
          this.loading = false
        })
    },
  },
  created() {
    this.fetchProducts()
  },
  computed: {
    ...mapGetters('product', ['products', 'info']),
  },
}
</script>
