<template>
  <TransitionRoot :show="show" as="template">
    <Dialog as="div">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center bg-gray-200 bg-opacity-50">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true"> &#8203; </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="
                inline-block
                w-full
                max-w-lg
                p-6
                my-8
                overflow-hidden
                text-left
                align-middle
                transition-all
                transform
                bg-white
                shadow-xl
                rounded-2xl
                space-y-6
              "
            >
              <DialogTitle as="h3" class="text-lg font-medium leading-4 text-gray-600"> Manage Category </DialogTitle>
              <ManageCategoryModal :category="category" :fetchCategories="fetchCategories" @close="$emit('close')" />
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { TransitionRoot, TransitionChild, Dialog, DialogOverlay, DialogTitle } from '@headlessui/vue'
import ManageCategoryModal from './ManageCategory.vue'

export default {
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    ManageCategoryModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    category: {
      type: Object,
    },
    fetchCategories: {
      type: Function,
    },
  },
  data() {
    return {
      errors: {},
      loading: false,
    }
  },
  emits: ['close'],
}
</script>
