<template>
  <TransitionRoot :show="show" as="template">
    <Dialog as="div" @close="$emit('close')">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center bg-gray-200 bg-opacity-50">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true"> &#8203; </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="
                inline-block
                w-full
                max-w-md
                p-6
                my-8
                overflow-hidden
                text-left
                align-middle
                transition-all
                transform
                bg-white
                shadow-xl
                rounded-2xl
                space-y-6
              "
            >
              <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                You are now registered to {{ name }}
              </DialogTitle>
              <div class="mt-2">
                <slot name="confirmText">
                  <p class="text-sm text-gray-700">
                    <span class="text-green-700">Congratulations</span>, the plugin trial for {{ name }} has been added
                    to your subscription
                  </p>
                  <p class="text-sm text-gray-700 mt-1">We have sent an email to your email address</p>
                  <p class="text-sm text-gray-700 mt-1">
                    The email will contain instructions on how to install and start using the {{ name }}
                  </p>
                  <p class="text-sm text-gray-700 mt-1">
                    If you have any question, please contact
                    <a
                      class="text-blue-600 cursor-pointer underline decoration-sky-500"
                      target="_blank"
                      href="https://www.oscillosoft.com.au"
                      >Customer Support</a
                    >
                  </p>
                </slot>
              </div>

              <div class="mt-6 flex justify-center">
                <button
                  type="button"
                  class="
                    inline-flex
                    items-center
                    justify-center
                    px-4
                    py-1.5
                    text-sm
                    font-medium
                    text-blue-900
                    bg-blue-100
                    border border-transparent
                    rounded-md
                    hover:bg-blue-200
                    focus:outline-none
                    focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500
                  "
                  @click="$emit('close')"
                >
                  Close
                </button>
                <slot>
                  <button
                    type="button"
                    class="
                      w-42
                      inline-flex
                      items-center
                      justify-center
                      px-4
                      py-1.5
                      ml-2
                      text-sm
                      font-medium
                      text-white
                      bg-blue-600
                      border border-transparent
                      rounded-md
                      hover:bg-blue-700
                      focus:outline-none
                      focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500
                    "
                    @click="$emit('confirm')"
                  >
                    Go to subscription
                  </button>
                </slot>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { TransitionRoot, TransitionChild, Dialog, DialogOverlay, DialogTitle } from '@headlessui/vue'

export default {
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    name: String,
  },
  emits: ['close', 'confirm'],
}
</script>
