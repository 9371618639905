<template>
  <TransitionRoot as="template" :show="show">
    <Dialog as="div" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed mt-16 inset-y-0 pl-16 max-w-full right-0 flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-lg">
              <form
                class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl"
                @submit.prevent="updateProduct"
                enctype="multipart/form-data"
              >
                <div class="flex-1 h-0 overflow-y-auto">
                  <!-- Close button -->
                  <div class="pt-3 px-4 sm:px-4 flex items-start">
                    <div class="flex items-center">
                      <button
                        type="button"
                        class="
                          bg-gray-200
                          rounded-full
                          p-1
                          text-gray-500
                          hover:text-gray-600
                          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600
                        "
                        @click="$emit('close-sidebar')"
                      >
                        <span class="sr-only">Close panel</span>
                        <XIcon class="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <!-- Main Form area -->
                  <div v-if="planEditDelay" class="flex-1 flex flex-col justify-between">
                    <div class="divide-y divide-gray-200">
                      <div class="space-y-8 pt-4 pb-5 divide-y-2 divide-gray-200">
                        <div class="px-4 md:px-6 flex flex-row flex-wrap gap-2 justify-between ...">
                          <div>
                            <h2 class="text-md font-medium text-gray-600 block">Zoho subscription default fields</h2>
                            <p class="block text-sm font-medium text-gray-500">Name: {{ pluginData.name }}</p>
                            <p class="block text-sm font-medium text-gray-500">
                              Price: $ {{ pluginData.recurring_price }}
                            </p>
                            <p class="block text-sm font-medium text-gray-500">
                              Interval: {{ pluginData.interval_unit }}
                            </p>
                          </div>
                          <img :src="logo" alt="plugin logo" class="w-30 h-24 rounded-full" />
                        </div>
                        <div class="px-4 md:px-6 pt-4">
                          <h2 class="hidden text-md font-medium text-gray-600 mb-6 border-b border-gray-200">
                            Customizable fields
                          </h2>
                          <div class="flex flex-col gap-6">
                            <div>
                              <text-input
                                label="Plugin name(publishable)"
                                placeholder="Ex: Xero2Zoho"
                                class="!bg-white"
                                v-model="pluginData.name"
                                readonly
                              />
                            </div>
                            <div class="flex flex-col gap-6">
                              <text-input
                                label="Plugin logo upload"
                                placeholder="Ex: Xero2Zoho"
                                class="!bg-white"
                                :class="{ 'base-error': isLogoValid }"
                                type="file"
                                @change="onFileSelected"
                              />
                              <label v-if="isLogoValid" class="text-xs text-red-600 -mt-4">{{ fileErrorMsg }}</label>
                            </div>
                            <div class="-mb-5">
                              <button
                                class="
                                  inline-flex
                                  items-center
                                  bg-purple-500
                                  py-1.5
                                  px-3
                                  rounded-md
                                  shadow-sm
                                  text-sm
                                  font-medium
                                  text-white
                                  focus:outline-none
                                  float-right
                                "
                                @click.prevent="addCategory"
                              >
                                <span class="ml-2">Add New Category</span>
                              </button>
                            </div>
                            <div>
                              <div class="w-full flex flex-row">
                                <div class="w-full items-start">
                                  <base-select
                                    :options="categories"
                                    v-model="category"
                                    label="Plugin category"
                                    class="flex-grow"
                                    :class="{ 'base-error': isCategorySelected }"
                                  />
                                  <label v-if="isCategorySelected" class="text-xs text-red-600 -mt-4"
                                    >Please select a category</label
                                  >
                                </div>
                                <div class="items-end">
                                  <button
                                    @click.prevent="editCategory"
                                    class="mt-7 ml-2"
                                    :class="{
                                      'text-gray-300 pointer-events-none': hasEditableCategory,
                                      'text-yellow-600': !hasEditableCategory,
                                    }"
                                  >
                                    <PencilAltIcon class="w-6 h-6" />
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div>
                              <TextArea v-model="pluginData.description" label="Description..." />
                            </div>

                            <div>
                              <text-input
                                :class="{ 'base-error': isValid }"
                                label="Plugin URL"
                                v-model="pluginData.plugin_url"
                                placeholder="Ex: xero2zoho.oscillosoft....."
                                @blur="validURL(pluginData.plugin_url)"
                              />
                            </div>
                            <div>
                              <text-input
                                :class="{ 'base-error': isValid }"
                                label="Wordpress URL"
                                v-model="pluginData.wordpress_url"
                                placeholder="Ex: wordpress.oscillosoft....."
                                @blur="validURL(pluginData.wordpress_url)"
                              />
                            </div>
                            <div>
                              <text-input
                                :class="{ 'base-error': isValid }"
                                label="Zoho marketplace URL"
                                v-model="pluginData.z_marketplace_url"
                                placeholder="Ex: wordpress.oscillosoft....."
                                @blur="validURL(pluginData.z_marketplace_url)"
                              />
                            </div>
                            <div>
                              <text-input
                                :class="{ 'base-error': isValid }"
                                label="Subscription webhook"
                                v-model="pluginData.subscribed_webhook"
                                placeholder="Ex: xero2zoho.saasplugin.com.au/api/add-user"
                                @blur="validURL(pluginData.subscribed_webhook)"
                              />
                            </div>

                            <div>
                              <text-input
                                :class="{ 'base-error': isValid }"
                                label="Unsubscription webhook"
                                v-model="pluginData.unsubscribed_webhook"
                                placeholder="Ex: xero2zoho.saasplugin.com.au/api/unsubscribed"
                                @blur="validURL(pluginData.unsubscribed_webhook)"
                              />
                            </div>

                            <div class="">
                              <base-select
                                :options="statusOptions"
                                v-model="pluginData.status"
                                label="Current status"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ProcessingLoader as="template" v-else />
                </div>
                <div class="flex-shrink-0 px-4 py-4 flex justify-end gap-2">
                  <Button
                    :class="'bg-white text-gray-700 hover:bg-gray-50 border border-gray-300 shadow-sm'"
                    type="button"
                    @click="$emit('close-sidebar')"
                  >
                    <slot>
                      <span>Cancel</span>
                    </slot>
                  </Button>
                  <Button
                    :class="'bg-purple-200 text-purple-800 hover:bg-gray-50 border border-gray-300 shadow-sm'"
                    type="submit"
                    :disabled="loading"
                  >
                    <slot>
                      <Spinner v-if="loading" :size="4" :color="'text-blue-500'" />
                      <span class="ml-2">Save changes</span>
                    </slot>
                  </Button>
                </div>
              </form>
              <ManageCategoryModal
                :show="consent"
                :category="categoryDetails"
                :fetchCategories="fetchCategories"
                @close="close"
                as="template"
              />
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XIcon, PencilAltIcon } from '@heroicons/vue/outline'
import TextInput from '../../../components/BaseInput.vue'
import BaseSelect from '../../../components/BaseSelect.vue'
import Button from '../../../components/CommonButton.vue'
import ProcessingLoader from '../../../components/ProcessingLoader.vue'
import TextArea from '../../../components/TextArea.vue'
import Spinner from '../../../components/Spinner.vue'
import ManageCategoryModal from './ManageCategoryContainer.vue'
import { mapGetters, mapActions } from 'vuex'
const FormData = require('form-data')
const statusOptions = [
  { id: 'active', name: 'Active' },
  { id: 'inactive', name: 'Deactivate' },
]
export default {
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    XIcon,
    PencilAltIcon,
    TextInput,
    BaseSelect,
    Button,
    ProcessingLoader,
    ManageCategoryModal,
    TextArea,
    Spinner,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    pluginId: {
      type: Number,
      default: 0,
    },
    planEditDelay: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close-sidebar', 'updateDelay'],
  data() {
    return {
      open: this.show,
      logo: null,
      logoUpload: null,
      category: null,
      description: null,
      categories: [],
      statusOptions,
      isValid: false,
      isLogoValid: false,
      isCategorySelected: false,
      hasEditableCategory: true,
      fileLength: 0,
      fileErrorMsg: null,
      loading: false,
      categoryDetails: {},
      consent: false,
    }
  },
  updated() {
    if (!this.planEditDelay && this.show) {
      if (!this.categories.length) {
        this.fetchCategories()
      }
      this.fetchPluginDetailsById()
    }
    this.initializeVariables()
  },
  computed: {
    ...mapGetters('plugin', { pluginData: 'getPluginDetails' }),
  },
  methods: {
    ...mapActions('plugin', ['getPluginDetailsById', 'getCategories', 'update']),
    capitalizeFirstLetter(string) {
      let data = string == 'inactive' ? 'disabled' : string
      return data.charAt(0).toUpperCase() + data.slice(1)
    },
    validURL(str) {
      console.log(str)
    },
    onFileSelected(event) {
      let selectedFile = event.target.files[0]
      let isSizeValid = this.validateLogoSize(selectedFile)
      let isExtensionValid = this.validateLogoExtension(selectedFile)
      this.fileLength = event.target.files.length
      if (!isSizeValid && !isExtensionValid) {
        this.isLogoValid = false
        this.logoUpload = event.target.files[0]
        this.fileErrorMsg = ''
      } else {
        this.isLogoValid = true
        if (isExtensionValid) {
          this.fileErrorMsg = 'Only "jpeg", "jpg", "png", "gif" format is allowed'
        }
        if (isSizeValid) {
          this.fileErrorMsg = 'Please provide less then 2 MB'
        }
      }
      // this.validateLogoDimension(selectedFile).then((isDimensionValid) => {

      // })
      console.log(this.isLogoValid)
    },
    validateLogoSize(file) {
      if (this.bytesToMegaBytes(file.size) > 2048) {
        return true
      }
      return false
    },
    bytesToMegaBytes(bytes) {
      return (bytes / (1024 * 1024)).toFixed(2)
    },
    validateLogoDimension: (file) =>
      new Promise((resolve, reject) => {
        try {
          let img = new Image()

          img.onload = () => {
            const width = img.naturalWidth,
              height = img.naturalHeight
            let isValid = false

            window.URL.revokeObjectURL(img.src)
            if (width > 200 || height > 200) {
              isValid = true
            }
            return resolve(isValid)
          }

          img.src = window.URL.createObjectURL(file)
        } catch (exception) {
          return reject(exception)
        }
      }),
    validateLogoExtension(file) {
      var allowedExtension = ['jpeg', 'jpg', 'png', 'gif']
      var fileExtension = file.name.split('.').pop().toLowerCase()

      for (var index in allowedExtension) {
        if (fileExtension === allowedExtension[index]) {
          return false
        }
      }
      return true
    },
    fetchCategories() {
      this.getCategories({})
        .then((res) => {
          this.categories = []
          this.categories.push({ id: '', name: 'Select a category' })
          for (let i = 0; i < res.data.data.length; i++) {
            this.categories.push(res.data.data[i])
          }
          this.findCategoryById()
        })
        .catch((err) => {
          const notification = {
            type: 'error',
            message: err,
          }
          this.$store.dispatch('notification/add', notification, { root: true })
        })
    },
    fetchPluginDetailsById() {
      let id = this.pluginId
      let selectedCategoryId = null
      this.getPluginDetailsById({ id })
        .then(() => {
          if (this.pluginData.categories && this.pluginData.categories.length > 0) {
            selectedCategoryId = this.pluginData.categories[0].id
          }
          this.logo = this.pluginData.logo || '/plugin-logo.png'
          this.category = selectedCategoryId
        })
        .then(() => {
          this.$emit('updateDelay', true)
        })
        .catch((err) => {
          const notification = {
            type: 'error',
            message: err,
          }
          this.$store.dispatch('notification/add', notification, { root: true })
        })
    },
    formatePlanData() {
      const data = new FormData()
      data.append('id', this.pluginId)
      data.append('name', this.pluginData.name)
      if (this.fileLength !== 0) {
        data.append('logo', this.logoUpload, this.logoUpload.name)
      }
      data.append('category_id', this.category)
      data.append('description', this.pluginData.description || '')
      data.append('plugin_url', this.pluginData.plugin_url || '')
      data.append('wordpress_url', this.pluginData.wordpress_url || '')
      data.append('z_marketplace_url', this.pluginData.z_marketplace_url || '')
      data.append('subscribed_webhook', this.pluginData.subscribed_webhook || '')
      data.append('unsubscribed_webhook', this.pluginData.unsubscribed_webhook || '')
      data.append('status', this.pluginData.status)
      return data
    },
    updateProduct() {
      // this.isCategorySelected = this.category == 0 ? true : false
      // if (!this.isLogoValid && !this.isCategorySelected) {
      if (!this.isLogoValid) {
        this.loading = true
        let planData = this.formatePlanData()
        this.update(planData)
          .then((res) => {
            const notification = {
              type: res.has_error ? 'error' : 'success',
              message: res.message,
            }
            this.triggerNotification(notification)
            if (!res.has_error) {
              this.$emit('close-sidebar')
            }
          })
          .catch((err) => {
            if (err.error) {
              console.log(err)
            } else {
              const notification = {
                type: err.has_error ? 'error' : 'success',
                message: err.message,
              }
              this.triggerNotification(notification)
            }
          })
          .then(() => {
            this.loading = false
          })
      }
    },
    addCategory() {
      this.categoryDetails = { id: '', name: '' }
      this.consent = true
    },
    editCategory() {
      this.consent = true
    },
    close() {
      this.consent = false
    },
    //For better user experience delay the notification
    triggerNotification(notification) {
      this.timeout = setTimeout(() => {
        this.$store.dispatch('notification/add', notification)
      }, 500)
    },
    initializeVariables() {
      this.isValid = false
      this.isLogoValid = false
      this.fileLength = 0
      this.ileErrorMsg = null
    },
    findCategoryById() {
      if (this.category !== '' && this.category !== null) {
        this.categoryDetails = this.categories.find((item) => item.id == this.category)
      }
    },
  },
  watch: {
    category: {
      handler: function () {
        this.hasEditableCategory = this.category !== '' && this.category !== null ? false : true
        this.findCategoryById()
      },
    },
  },
}
</script>
