import UserService from '../../services/UserService'

export const namespaced = true

export const state = {
  user: {},
  authenticated: false,
  permissions: [],
  verified: false,
  forbidden: false,
}

export const mutations = {
  SET_AUTHENTICATED(state, auth) {
    state.authenticated = auth
    localStorage.setItem('authenticated', auth)
  },
  SET_VERIFIED(state, verify) {
    state.verified = verify
    localStorage.setItem('verified', verify)
  },
  SET_FORBIDDEN(state, forbidden) {
    state.forbidden = forbidden
  },
  SET_USER(state, user) {
    state.user = user
  },
  SET_PERMISSIONS(state, permissions) {
    state.permissions = permissions
  },
  CLEAR_USER_DATA() {
    localStorage.removeItem('authenticated')
    localStorage.removeItem('verified')
    location.reload()
  },
}

export const actions = {
  async register({ commit }, credentials) {
    return await UserService.register(credentials).then(() => {
      commit('SET_AUTHENTICATED', true)
    })
  },
  async login({ commit }, credentials) {
    return await UserService.login(credentials).then(() => {
      commit('SET_AUTHENTICATED', true)
    })
  },
  async logout({ commit }) {
    return await UserService.logout().then(() => {
      commit('CLEAR_USER_DATA')
    })
  },
  async resendVerification() {
    return await UserService.resendVerification()
  },
  async forgotPassword(_, credentials) {
    return await UserService.forgotPassword(credentials)
  },
  async resetPassword(_, credentials) {
    return await UserService.resetPassword(credentials)
  },
  async confirmVerification(_, path) {
    return await UserService.confirmVerification(path)
  },
  async checkRegistration({ commit }, user) {
    commit('SET_USER', user)
    return await UserService.checkRegistration()
  },
  async fetchUser({ commit, state }) {
    if (typeof state.user.id === 'undefined' && !state.forbidden) {
      return await UserService.user()
        .then((response) => {
          commit('SET_VERIFIED', true)
          commit('SET_USER', response.data)
        })
        .catch(() => {
          commit('SET_FORBIDDEN', true)
        })
    }
  },
  async fetchPermissions({ commit }) {
    return await UserService.permissions()
      .then(({ data }) => {
        commit('SET_PERMISSIONS', data)
      })
      .catch(() => {
        //
      })
  },
}

export const getters = {
  getUser(state) {
    return state.user
  },
}
