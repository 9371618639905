<template>
  <div class="animate-pulse">
    <div class="mt-4 flex flex-row flex-wrap gap-4 sm:gap-8 justify-start">
      <div
        v-for="item in [1, 2, 3, 4]"
        :key="item"
        class="border border-gray-200 shadow px-4 py-6 w-48 sm:w-52 overflow-hidden bg-white rounded-md"
      >
        <div class="flex flex-col items-center gap-2 sm:gap-6 h-full justify-between">
          <div class="flex flex-col items-center gap-4">
            <div class="w-24 h-24 rounded-full bg-gray-200"></div>
            <div class="w-full flex flex-col gap-1 items-center">
              <div class="w-full h-4 text-blue-500 text-xs font-bold text-center bg-gray-200 rounded-md"></div>
              <div class="w-2/3 h-4 text-blue-500 text-xs font-bold text-center bg-gray-200 rounded-md"></div>
              <div class="w-full h-4 text-blue-500 text-xs font-bold text-center bg-gray-200 rounded-md"></div>
            </div>
          </div>
          <div class="space-y-2 w-full">
            <slot>
              <button
                class="
                  w-full
                  h-7
                  border border-transparent
                  bg-blue-200
                  text-white
                  shadow
                  text-xs
                  px-2
                  py-1.5
                  font-normal
                  rounded-full
                  self-end
                "
              ></button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
