<template>
  <h2 class="text-gray-600 text-md font-semibold pb-4">Account Summary</h2>
  <div class="bg-white py-8 px-8 rounded-md shadow">
    <div v-if="settingsDelay" class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      <div class="space-y-2">
        <div class="flex flex-row gap-4">
          <h2 class="text-gray-600 text-sm font-bold">Number of Recurring Subscriptions:</h2>
          <h2 class="text-gray-600 text-sm font-medium">{{ totalSubscriptions }}</h2>
        </div>
        <div class="flex flex-row gap-4">
          <button
            class="
              text-white
              bg-blue-500
              shadow-md
              border border-transparent
              text-xs
              px-2
              py-1.5
              font-semibold
              rounded-full
              w-52
              mt-4
            "
            @click="managePayment"
          >
            Manage Payment Method
          </button>
        </div>
      </div>
    </div>
    <ProcessingLoader v-else as="template" class="mt-4" />
  </div>
  <div class="bg-white pb-8 pt-4 px-8 rounded-md shadow mt-12">
    <div class="mt-4 sm:mt-8">
      <h2 class="text-sm text-gray-600 font-semibold">My current subscriptions</h2>
      <div class="mt-4 overflow-x-auto sm:rounded">
        <table class="min-w-full">
          <thead class="bg-gray-100">
            <tr>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Purchase date
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Product
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Billing cycle
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Total
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                End date
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Payment method
              </th>
            </tr>
          </thead>
          <tbody v-if="settingsDelay">
            <tr
              v-for="(billing, index) in billings"
              :key="billing.id"
              :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
            >
              <td class="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-500">
                {{ lastBilling(billing) }}
              </td>
              <td class="px-6 py-2 text-sm text-gray-500">{{ billing.name }}</td>
              <td class="px-6 py-2 text-sm text-gray-500">{{ billingCycle(billing) }}</td>
              <td class="px-6 py-2 text-sm text-gray-500">{{ billing.amount }}</td>
              <td class="px-6 py-2 text-sm text-gray-500">{{ expireBilling(billing) }}</td>
              <td class="px-6 py-2 text-sm text-gray-500">{{ formatStatus(billing.status) }}</td>
              <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500">{{ cardInfo(billing) }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="7">
                <ProcessingLoader as="template" class="mt-4" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <PaymentSettingsModal :show="consent" :payment="null" :card="manageCard" @close="close" as="template" />
    <NotificationModal :show="notify" @close="this.notify = false" as="template" />
  </div>
</template>

<script>
import ProcessingLoader from '../../components/ProcessingLoader.vue'
import PaymentSettingsModal from '../settings/partials/PaymentSettingsModal.vue'
import NotificationModal from '../settings/partials/NotificationModal.vue'
import { mapGetters, mapActions } from 'vuex'
const intervalUnit = {
  years: 'Yearly',
  months: 'Monthly',
  weeks: 'Weekly',
  days: 'Daily',
  day: 'Daily',
}

export default {
  components: {
    ProcessingLoader,
    PaymentSettingsModal,
    NotificationModal,
  },
  data() {
    return {
      settingsDelay: false,
      consent: false,
      notify: false,
    }
  },
  async created() {
    this.fetchSummery()
    this.fetchBillingInfo()
  },
  methods: {
    ...mapActions('setting', ['getBillingInfo', 'getSummary']),
    fetchBillingInfo() {
      this.getBillingInfo({})
        .then(() => {
          this.settingsDelay = true
        })
        .catch((err) => {
          const notification = {
            type: 'error',
            message: err,
          }
          this.$store.dispatch('notification/add', notification, { root: true })
        })
    },
    fetchSummery() {
      this.getSummary({})
        .then(() => {
          this.settingsDelay = true
        })
        .catch((err) => {
          const notification = {
            type: 'error',
            message: err,
          }
          this.$store.dispatch('notification/add', notification, { root: true })
        })
    },
    lastBilling(billing) {
      return billing.last_billing_at == '0000-00-00' || billing.last_billing_at == null
        ? '---'
        : billing.last_billing_at
    },
    expireBilling(billing) {
      return billing.expires_at == '0000-00-00' || billing.expires_at == null ? '---' : billing.expires_at
    },
    billingCycle(billing) {
      return intervalUnit[billing.interval_unit]
    },
    cardInfo(billing) {
      let card = JSON.parse(billing.card)
      return card
        ? (card.last_four_digits ? '.... xxxx ' + card.last_four_digits : '') +
            ' | ' +
            (card.gateway ? card.gateway.toUpperCase() : '')
        : ''
    },
    formatStatus(status) {
      return status.split('_').join(' ')
    },
    managePayment() {
      if (!this.checkCard()) {
        this.notify = true
        return false
      }
      this.consent = true
    },
    checkCard() {
      return (
        this.billings.filter(
          (item) => (item.status == 'TRIAL' || item.status == 'LIVE' || item.status == 'ACTIVE') && item.card != null
        ).length > 0
      )
    },
    close() {
      this.consent = false
    },
  },
  computed: {
    ...mapGetters('setting', ['summary', 'billings', 'info']),
    totalSubscriptions() {
      return this.summary.active_subscriptions ? this.summary.active_subscriptions : 0
    },
    manageCard() {
      if (this.billings) {
        return [{ id: '', name: 'Select a subscription' }].concat(
          this.billings
            .filter((item) => item.card && (item.status == 'ACTIVE' || item.status == 'LIVE' || item.status == 'TRIAL'))
            .map((item) => {
              let card = item.card ? JSON.parse(item.card) : {}
              return {
                id: item.id,
                name: item.name,
                type: card.gateway ? card.gateway.toUpperCase() : 'N/A',
                last_four: card.last_four_digits ? card.last_four_digits : 'N/A',
              }
            })
        )
      } else {
        return [{ id: '', name: 'Select a subscription' }]
      }
    },
  },
}
</script>
