import http from './Http/Client'

export default {
  getUsersInfo() {
    return http.get(process.env.VUE_APP_API_PREFIX + 'setting/user')
  },
  getBillingInfo() {
    return http.get(process.env.VUE_APP_API_PREFIX + 'setting/billing')
  },
  getSummary() {
    return http.get(process.env.VUE_APP_API_PREFIX + 'setting/subscription-summary')
  },
  userDetailsUpdate(params) {
    return http.post(process.env.VUE_APP_API_PREFIX + 'setting/user', params)
  },
  updatePaymentMethod(params) {
    return http.post(process.env.VUE_APP_API_PREFIX + 'setting/update-payment-method', params)
  },
  manageCategory(params) {
    return http.post(process.env.VUE_APP_API_PREFIX + 'plugin/category', params)
  },
  countries(param) {
    return http.get(process.env.VUE_APP_API_PREFIX + 'setting/countries', param)
  },
  updatePassword(param) {
    return http.put(process.env.VUE_APP_API_PREFIX + 'setting/password', param)
  },
  async showQR() {
    await http.get('/user/confirmed-password-status')
    await http.post('/user/two-factor-authentication')
    return await http.get('/user/two-factor-qr-code')
  },
}
