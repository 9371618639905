<template>
  <div class="w-full rounded-md mx-auto">
    <div class="animate-pulse">
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        <div class="col-span-1 md:col-span-1">
          <div class="flex flex-col gap-4">
            <div class="w-28 h-28 rounded-full bg-gray-200"></div>
            <div class="w-2/3 h-8 bg-gray-200 rounded-md"></div>
            <div class="w-2/3 h-8 bg-gray-200 rounded-md"></div>
          </div>
        </div>
        <div class="col-span-1 md:col-span-2">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-8">
            <div class="col-span-1 flex flex-col gap-4 mt-4">
              <div class="h-8 bg-gray-200 rounded-md"></div>
              <div class="h-8 bg-gray-200 rounded-md"></div>
              <div class="h-8 bg-gray-200 rounded-md"></div>
              <div class="h-8 bg-gray-200 rounded-md"></div>
              <div class="h-8 bg-gray-200 rounded-md"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
