import { createRouter, createWebHistory } from 'vue-router'
import MainLayout from '../layouts/MainLayout.vue'
import Callback from '../components/Callback.vue'

import Dashboard from '../views/dashboard/Index.vue'
import Subscriptions from '../views/subscriptions/Index.vue'
import Plugins from '../views/plugins/Index.vue'
import Products from '../views/products/Index.vue'
import Product from '../views/products/Product.vue'
import EditProduct from '../views/products/EditProduct.vue'
import Settings from '../views/settings/Index.vue'
import Account from '../views/settings/Account.vue'
import Confirm from '../views/subscriptions/Confirmation.vue'
import VerifyEmail from '../views/User/EmailVerification.vue'
import Registration from '../views/User/CompleteRegistration.vue'
import NotFound from '../views/Errors/404.vue'
import Unauthorized from '../views/Errors/403.vue'
import { authenticationGuard } from '../auth0/Auth-guard'

const routes = [
  {
    path: '/',
    component: MainLayout,
    beforeEnter: authenticationGuard,
    children: [
      {
        path: '/',
        redirect: {
          name: 'plugins',
        },
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
      },
      {
        path: '/plugins',
        name: 'plugins',
        component: Plugins,
      },
      {
        path: '/subscriptions',
        name: 'subscriptions',
        component: Subscriptions,
      },
      {
        path: '/products',
        name: 'products',
        component: Products,
      },
      {
        path: '/product/:id',
        name: 'product',
        component: Product,
      },
      {
        path: '/product/plan/:id/edit',
        name: 'updatePlugin',
        component: EditProduct,
      },
      {
        path: '/settings',
        name: 'settings',
        component: Settings,
      },
      {
        path: '/billings',
        name: 'account',
        component: Account,
      },
      {
        path: '/subscription/confirmation',
        name: 'confirm',
        component: Confirm,
      },
      {
        path: '/unauthorized',
        name: 'unthorized',
        component: Unauthorized,
      },
    ],
  },
  {
    path: '/callback',
    name: 'callback',
    component: Callback,
  },
  {
    path: '/verify-mail',
    name: 'verifyMail',
    beforeEnter: authenticationGuard,
    component: VerifyEmail,
  },
  {
    path: '/registration',
    name: 'completeRegistration',
    beforeEnter: authenticationGuard,
    component: Registration,
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: NotFound,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
