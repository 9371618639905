<template>
  <TransitionRoot :show="show" as="template">
    <Dialog as="div" @close="$emit('close')">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center bg-gray-200 bg-opacity-50">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true"> &#8203; </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="
                inline-block
                w-full
                max-w-lg
                py-8
                px-8
                overflow-hidden
                text-left
                align-middle
                transition-all
                transform
                bg-white
                shadow-xl
                rounded-2xl
                space-y-6
              "
            >
              <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                <slot name="title"> Are you sure? </slot>
              </DialogTitle>
              <div class="mt-2">
                <slot name="body">
                  <p class="text-sm text-gray-500">Default messsage goes here...</p>
                </slot>
              </div>

              <div class="mt-10 flex justify-end">
                <button
                  type="button"
                  class="
                    inline-flex
                    items-center
                    justify-center
                    px-4
                    py-1.5
                    text-sm
                    font-medium
                    text-blue-900
                    bg-blue-100
                    border border-transparent
                    rounded-md
                    hover:bg-blue-200
                    focus:outline-none
                    focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500
                  "
                  @click="$emit('close')"
                >
                  Close
                </button>
                <slot>
                  <ConfirmButton @click="$emit('confirm')" />
                </slot>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { TransitionRoot, TransitionChild, Dialog, DialogOverlay, DialogTitle } from '@headlessui/vue'
import ConfirmButton from '../components/ConfirmationButton.vue'

export default {
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    ConfirmButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: String,
  },
  emits: ['close', 'confirm'],
}
</script>
