<template>
  <div class="bg-white py-8 px-8 rounded-md shadow">
    <div class="flex flex-row justify-start gap-4 items-center">
      <select-category v-model="category" :options="categories" />
      <search-box
        v-model="searchText"
        placeholder="Search plugin..."
        @keyup.enter="fetchPluginsBySearch"
        @clear="clearSearch"
      />
    </div>
    <div class="mt-8">
      <h2 class="text-sm font-bold text-gray-600">New Plugins</h2>
      <div v-if="pluginDelay" class="mt-4 flex flex-row flex-wrap gap-4 sm:gap-6 sm:justify-start">
        <Plugin
          v-for="plugin in plugins"
          :key="plugin.id"
          :plugin="plugin"
          class="hover:cursor-pointer"
          @open-sidebar="openSidebar"
        />
        <div v-if="plugins.length == 0" class="flex flex-row">
          <p class="text-sm font-medium text-gray-600">There is no available plugins for subscription</p>
        </div>
        <FetchProgressLoader v-if="isNextFetch" as="template" />
      </div>
      <ProcessingLoader v-else as="template" class="mt-4" />
    </div>
  </div>

  <side-view :plugin="pluginTryNow" ref="updateSideView" :show="open" @closeSidebar="close" />
</template>

<script>
import SelectCategory from '../../components/BaseSelect.vue'
import SearchBox from '../../components/SearchBox.vue'
import Plugin from './partials/Plugin.vue'
import SideView from './partials/Sideview.vue'
import { mapGetters } from 'vuex'
import ProcessingLoader from '../plugins/partials/PluginsPreloader.vue'
import FetchProgressLoader from '../../components/FetchProgressLoader.vue'

export default {
  components: {
    SelectCategory,
    SearchBox,
    Plugin,
    SideView,
    ProcessingLoader,
    FetchProgressLoader,
  },
  data() {
    return {
      open: false,
      pluginTryNow: {},
      pluginDelay: false,
      category: 0,
      categories: [],
      searchText: null,
      page: 1,
      nextFetch: false,
      isNextFetch: false,
    }
  },
  methods: {
    openSidebar({ plugin }) {
      this.pluginTryNow = plugin
      this.open = true
    },
    fetchPlugins() {
      let filter_by = 'status.active'
      let page = this.page
      let search = this.searchText
      let category_id = this.category
      this.$store
        .dispatch('plugin/nonSubscribePlugin', { filter_by, category_id, search, page })
        .then(() => {
          this.pluginDelay = true
          this.nextFetch = true
          this.isNextFetch = false
        })
        .catch((err) => {
          const notification = {
            type: 'error',
            message: err,
          }
          this.$store.dispatch('notification/add', notification, { root: true })
        })
    },
    fetchMorePlugins() {
      if (this.nextFetch && this.$store.state.plugin.plugins.info.more_records && this.pluginDelay) {
        if (window.scrollY > 150) {
          this.isNextFetch = true
          this.page = this.page + 1
          this.fetchPlugins()
          this.nextFetch = false
        }
      }
    },
    fetchPluginsBySearch() {
      this.pluginDelay = false
      this.page = 1
      this.fetchPlugins()
    },
    getCategories() {
      this.categories.push({ id: 0, name: 'All' })
      this.$store
        .dispatch('plugin/getCategories', {})
        .then((res) => {
          for (let i = 0; i < res.data.data.length; i++) {
            this.categories.push(res.data.data[i])
          }
        })
        .catch((err) => {
          const notification = {
            type: 'error',
            message: err,
          }
          this.$store.dispatch('notification/add', notification, { root: true })
        })
    },
    updateDelay(status) {
      this.subscriptionDelay = status
    },
    close() {
      this.open = false
      //this.pluginTryNow = {}
    },
    clearSearch() {
      this.searchText = null
      this.fetchPluginsBySearch()
    },
  },
  watch: {
    category: {
      handler: function () {
        this.page = 1
        this.fetchPlugins()
      },
    },
    searchText: {
      handler: function () {
        if (this.searchText == '') {
          this.fetchPluginsBySearch()
        }
      },
    },
  },
  created() {
    this.getCategories()
    this.fetchPlugins()
  },
  computed: {
    ...mapGetters('plugin', { plugins: 'plugins', info: 'info', subscriptionInfo: 'getPluginInfo' }),
  },
}
</script>
