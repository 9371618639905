<template>
  <div v-if="auth0 && auth0.isLoading.value" class="bg-white">
    <Loader />
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import { useAuth0 } from './auth0/Authentication'
import Loader from './components/PreLoader.vue'
import { onMounted } from 'vue'
export default {
  components: { Loader },
  setup() {
    const auth0 = useAuth0()
    onMounted(async () => {
      if (!auth0) return

      await auth0.createClient()
      await auth0.handleCallback()
    })

    return {
      auth0,
    }
  },
  /* watch: {
    $route: {
      handler: function (to, from) {
        console.log('Route changed from ' + from.path + ' to ' + to.path)
      },
    },
  }, */
}
</script>
