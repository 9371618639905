<template>
  <button
    type="button"
    :class="[
      'inline-flex items-center justify-center px-4 py-1.5 ml-2 text-sm font-medium text-white border border-transparent rounded-md focus:outline-none',
    ]"
    :disabled="loading"
  >
    <slot>Confirm</slot>
    <Spinner v-if="loading" class="ml-1.5" />
  </button>
</template>

<script>
import Spinner from './Spinner.vue'

export default {
  components: {
    Spinner,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
