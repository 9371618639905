<template>
  <BaseButton
    :type="type"
    class="
      w-full
      flex
      justify-center
      py-2
      px-4
      border border-transparent
      rounded-md
      shadow-sm
      text-sm
      font-medium
      text-white
      bg-indigo-600
      hover:bg-indigo-700
      focus:outline-none
    "
  >
    <slot></slot>
  </BaseButton>
</template>

<script>
import BaseButton from '../../../components/BaseButton.vue'

export default {
  components: {
    BaseButton,
  },
  props: {
    type: {
      type: String,
      default: 'button',
    },
  },
}
</script>
