<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div
    aria-live="assertive"
    class="fixed inset-0 z-20 flex flex-col px-4 py-6 pointer-events-none sm:p-6 sm:items-start gap-2"
  >
    <NotificationBar v-for="notification in notifications" :key="notification.id" :notification="notification" />
  </div>
</template>

<script>
import NotificationBar from './NotificationBar.vue'
import { mapState } from 'vuex'

export default {
  components: {
    NotificationBar,
  },
  computed: mapState('notification', ['notifications']),
}
</script>
