import axios from 'axios'
import { getAccessToken } from '../../auth0/Authentication'

const client = (options) => {
  const config = {
    baseURL: process.env.VUE_APP_API_SERVER_URL,
    headers: {
      'content-type': 'application/json',
    },
  }
  if (options.token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${options.token}`,
    }
  }
  const client = axios.create(config)

  return client
}

export default {
  async get(uri, param = {}) {
    return await client({ token: await getAccessToken() }).get(uri, param)
  },

  async post(uri, data) {
    return await client({ token: await getAccessToken() }).post(uri, data)
  },

  async put(uri, data) {
    return await client({ token: await getAccessToken() }).put(uri, data)
  },
}
