import http from './Http/Client'

export default {
  product(param) {
    return http.get(process.env.VUE_APP_API_PREFIX + 'plugin/product/' + param)
  },
  products(param) {
    return http.get(process.env.VUE_APP_API_PREFIX + 'plugin/products', param)
  },
  productSync() {
    return http.get(process.env.VUE_APP_API_PREFIX + 'plugin/getZSubscriptionProducts')
  },
}
