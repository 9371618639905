<template>
  <TransitionRoot :show="show" as="template">
    <Dialog as="div" @close="emitEvent">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center bg-gray-200 bg-opacity-50">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true"> &#8203; </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="
                inline-block
                w-full
                max-w-lg
                p-6
                my-8
                overflow-hidden
                text-left
                align-middle
                transition-all
                transform
                bg-white
                shadow-xl
                rounded-2xl
                space-y-6
              "
            >
              <DialogTitle as="h3" class="text-lg font-medium leading-4 text-gray-600">
                Update Payment Method</DialogTitle
              >
              <div class="">
                <label class="text-sm text-gray-500 font-normal">Select a subscription:</label>
                <select-payment
                  v-model="subscriptionId"
                  :options="card"
                  :class="[errors.subscription_id ? 'base-error' : '']"
                />
                <span class="text-xs text-red-400 font-normal italic" v-if="errors.subscription_id">
                  {{ errors.subscription_id }}
                </span>
              </div>

              <div v-if="cardDetails.id" class="flex flex-col mt-6 gap-2">
                <p class="block text-sm text-gray-500 font-medium self-end">
                  Current Card:
                  <strong class="italic text-gray-600">{{ '... xxxx ' + cardDetails.last_four }}</strong>
                </p>
                <p class="block text-sm text-gray-500 font-medium self-end">
                  Payment Method:
                  <strong class="italic text-gray-600">{{ cardDetails.type }}</strong>
                </p>
              </div>

              <div class="mt-6 flex justify-center">
                <button
                  type="button"
                  class="
                    inline-flex
                    items-center
                    justify-center
                    px-4
                    py-1.5
                    text-sm
                    font-medium
                    text-blue-900
                    bg-blue-100
                    border border-transparent
                    rounded-md
                    hover:bg-blue-200
                    focus:outline-none
                    focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500
                  "
                  @click="emitEvent"
                >
                  Close
                </button>
                <slot>
                  <button
                    type="button"
                    class="
                      w-42
                      inline-flex
                      items-center
                      justify-center
                      px-4
                      py-1.5
                      ml-2
                      text-sm
                      font-medium
                      text-white
                      bg-blue-600
                      border border-transparent
                      rounded-md
                      hover:bg-blue-700
                      focus:outline-none
                      focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500
                    "
                    @click="proceed"
                    :disabled="loading"
                  >
                    <Spinner v-if="loading" :size="4" />
                    <span class="ml-2">Proceed</span>
                  </button>
                </slot>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { TransitionRoot, TransitionChild, Dialog, DialogOverlay, DialogTitle } from '@headlessui/vue'
import SelectPayment from '../../../components/BaseSelect.vue'
import Spinner from '../../../components/Spinner.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    SelectPayment,
    Spinner,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    payment: {
      type: String,
      default: null,
    },
    card: {
      type: Object,
    },
  },
  data() {
    return {
      errors: {},
      loading: false,
      subscriptionId: this.payment,
      cardDetails: {},
    }
  },
  emits: ['close'],
  methods: {
    ...mapActions('setting', ['updatePaymentMethod']),
    proceed() {
      this.errors = {}
      this.hasPayment()
      if (!this.errors.subscription_id) {
        this.loading = true
        this.updatePaymentMethod(this.subscriptionId)
          .then(({ hostedpage }) => {
            window.location.href = hostedpage.url
          })
          .catch((err) => {
            this.notification(err.message)
          })
          .then(() => {
            this.loading = false
          })
      }
    },
    notification(message, type = 'error') {
      const notification = {
        type: type,
        message: message,
      }
      this.$store.dispatch('notification/add', notification, { root: true })
    },
    hasPayment() {
      !this.subscriptionId ? (this.errors['subscription_id'] = 'The subscription id field is required.') : ''
    },
    emitEvent() {
      this.$emit('close')
      this.errors = {}
      this.subscriptionId = ''
    },
    findCardById() {
      this.cardDetails = this.card.find((item) => item.id == this.subscriptionId)
    },
  },
  watch: {
    subscriptionId: {
      handler: function () {
        this.findCardById()
      },
    },
  },
}
</script>
