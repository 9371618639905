<template>
  <div>
    <label v-if="label" class="block text-xs sm:text-sm font-medium text-gray-600"
      >{{ label }} <span v-if="mandatory" class="text-red-500">*</span>
    </label>
    <div class="mt-1">
      <input
        :type="type"
        class="
          appearance-none
          block
          w-full
          px-3
          py-2
          border border-gray-300
          rounded-md
          shadow-sm
          placeholder-gray-400
          focus:outline-none focus:ring-indigo-500 focus:border-indigo-500
          sm:text-sm
        "
        :placeholder="getPlaceholder"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        v-bind="$attrs"
        :required="mandatory"
      />
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: [String, Number],
      default: '',
    },
    showPlaceholder: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'email',
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getPlaceholder() {
      if (!this.showPlaceholder) return ''

      if (this.placeholder) {
        return this.placeholder
      }
      return this.label
    },
  },
}
</script>
