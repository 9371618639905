<template>
  <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <div class="flex items-center justify-center pt-2 pb-8">
          <img src="logo-dark.png" class="w-56" alt="" />
        </div>
        <form class="space-y-6" @submit.prevent="register">
          <div>
            <text-input
              v-model="firstname"
              :mandatory="true"
              @keypress="isLetter($event)"
              label="First name"
              type="text"
              :class="[errors.first_name ? 'base-error' : '']"
            />
            <span class="text-xs text-red-400 font-normal italic" v-if="errors.first_name">{{
              errors.first_name
            }}</span>
          </div>
          <div>
            <text-input
              v-model="lastname"
              :mandatory="true"
              @keypress="isLetter($event)"
              label="Last name"
              type="text"
              :class="[errors.last_name ? 'base-error' : '']"
            />
            <span class="text-xs text-red-400 font-normal italic" v-if="errors.last_name">{{ errors.last_name }}</span>
          </div>
          <text-input
            v-model="organisation"
            @keypress="isLetterOrNumber($event)"
            :maxLength="maxInputLength"
            label="Organisation"
            type="text"
            :mandatory="true"
            :class="[errors.organisation ? 'base-error' : '']"
          />
          <span class="text-xs text-red-400 font-normal italic" v-if="errors.organisation">
            {{ errors.organisation }}
          </span>

          <div>
            <signup-button type="submit" :disabled="loading">
              <span class="mr-2">Continue...</span>
              <Spinner v-if="loading" />
            </signup-button>
          </div>
        </form>
        <div class="my-4">
          <small>To use this application you need to complete your sign up process.</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from './partials/TextInput.vue'
import SignupButton from './partials/LoginButton.vue'
import Spinner from '../../components/Spinner.vue'
import processErrors from '../../helpers/processErrors'
import { mapActions } from 'vuex'

// const emailRegex = RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
export default {
  components: {
    TextInput,
    SignupButton,
    Spinner,
  },
  data() {
    return {
      firstname: null,
      lastname: null,
      organisation: null,
      maxInputLength: 100,
      errors: {},
      loading: false,
    }
  },
  methods: {
    ...mapActions('user', ['fetchUser']),
    register() {
      this.hasLastName()
      this.hasOrganization()
      this.hasFirstName()
      if (!this.errors.last_name && !this.errors.organisation && !this.errors.first_name) {
        this.loading = true
        this.$store
          .dispatch('user/register', {
            first_name: this.firstname,
            last_name: this.lastname,
            organisation: this.organisation,
            country_code: this.$store.state.user.user['http://saasportal.com.au/country_code'],
            timezone: this.$store.state.user.user['http://saasportal.com.au/timezone'],
          })
          .then(async () => {
            await this.fetchUser()
            window.location.href = '/'
          })
          .catch(({ errors }) => {
            this.errors = processErrors(errors)
          })
          .then(() => {
            this.loading = false
          })
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode) // Get the character
      if (/^[A-Za-z. ]+$/.test(char)) return true
      else e.preventDefault() // If not match, don't add to input text
    },
    isLetterOrNumber(e) {
      let char = String.fromCharCode(e.keyCode)
      if (/^[A-Za-z0-9 ]+$/.test(char)) return true
      else e.preventDefault()
    },
    hasLastName() {
      !this.lastname.trim() ? (this.errors['last_name'] = 'Last name is required') : ''
    },
    hasFirstName() {
      !this.firstname.trim() ? (this.errors['first_name'] = 'First name is required') : ''
    },
    hasOrganization() {
      !this.organisation.trim() ? (this.errors['organisation'] = 'Organization name is required') : ''
    },
  },
  watch: {
    lastname: {
      handler: function () {
        delete this.errors['last_name']
      },
    },
    firstname: {
      handler: function () {
        delete this.errors['first_name']
      },
    },
    organisation: {
      handler: function () {
        delete this.errors['first_name']
      },
    },
  },
}
</script>
