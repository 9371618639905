import ProductService from '../../services/ProductService'

export const namespaced = true

export const state = {
  products: [],
  product: [],
}
export const mutations = {
  SET_PRODUCTS_DATA(state, { data, page }) {
    if (page == 1) {
      state.products = data
    } else {
      state.products.data.push(...data.data)
      state.products.info = data.info
    }
  },
}
export const actions = {
  products({ commit }, params) {
    let page = params.page
    return ProductService.products({ params }).then(({ data }) => {
      commit('SET_PRODUCTS_DATA', { data, page })
    })
  },
  product(_, param) {
    return ProductService.product(param)
  },
  sync() {
    return ProductService.productSync()
  },
}
export const getters = {
  products(state) {
    return state.products && state.products.data
  },
  info() {
    return state.products && state.products.info
  },
}
