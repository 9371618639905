<template>
  <div
    class="border border-gray-200 shadow px-4 pt-6 pb-4 w-36 sm:w-44 overflow-hidden bg-white rounded-md"
    @mouseover="showMeta = false"
    @mouseleave="showMeta = false"
  >
    <div class="flex flex-col items-center gap-2 sm:gap-4 h-full justify-between">
      <img :src="logo" alt="plugin logo" class="w-24 h-24 rounded-full" />
      <div class="flex flex-col gap-1">
        <p class="text-purple-600 text-xs font-bold text-center">{{ plugin.name }}</p>
      </div>
      <div v-if="showMeta" class="flex flex-col">
        <p class="text-gray-600 text-xs font-medium text-center">Price: $10.00</p>
      </div>
      <div class="flex flex-col items-center w-full gap-1.5">
        <slot>
          <TryNowButton @click="$emit('open-sidebar', { plugin })" />
        </slot>
        <a class="flex items-center text-xs font-medium text-gray-600 text-center" :href="pluginUrl" target="_blank"
          >Learn more
          <ExternalLinkIcon class="ml-0.5 w-3.5 h-3.5 flex-shrink-0 text-blue-400" aria-hidden="true" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { ExternalLinkIcon } from '@heroicons/vue/outline'
import TryNowButton from '@/components/TryNowButton.vue'

export default {
  components: {
    ExternalLinkIcon,
    TryNowButton,
  },
  props: {
    plugin: {
      type: Object,
      required: true,
    },
  },
  emits: ['open-sidebar'],
  setup() {
    const showMeta = ref(false)
    return {
      showMeta,
    }
  },
  computed: {
    logo() {
      return this.plugin.logo ? this.plugin.logo : 'plugin-logo.png'
    },
    pluginUrl() {
      if (this.plugin.wordpress_url == '' || this.plugin.wordpress_url == null) {
        return 'https://oscillosoft.com.au'
      } else {
        return this.plugin.wordpress_url.includes('https://')
          ? this.plugin.wordpress_url
          : 'https://' + this.plugin.wordpress_url
      }
    },
  },
}
</script>
