<template>
  <div class="">
    <text-input
      label="Category name"
      @keypress="isLetter"
      v-model="name"
      placeholder="Category name"
      :class="[errors.name ? 'base-error' : '']"
    />
    <span class="text-xs text-red-400 font-normal italic" v-if="errors.name">
      {{ errors.name }}
    </span>
    <text-input type="hidden" v-model="id" />
  </div>

  <div class="mt-6 flex justify-center">
    <Button :class="'text-blue-900 bg-blue-100 hover:bg-blue-200'" type="button" @click="emitEvent"> Close </Button>
    <Button :class="'ml-2 text-white bg-blue-600 hover:bg-blue-700'" :disabled="loading" type="button" @click="proceed">
      <slot> <Spinner v-if="loading" :size="4" /><span class="ml-2">Submit</span></slot>
    </Button>
  </div>
</template>
<script>
import TextInput from '../../../components/BaseInput.vue'
import Spinner from '../../../components/Spinner.vue'
import Button from '../../../components/CommonButton.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    TextInput,
    Spinner,
    Button,
  },
  props: {
    category: {
      type: Object,
    },
    fetchCategories: {
      type: Function,
    },
  },
  data() {
    return {
      errors: {},
      loading: false,
      name: this.category.name ?? null,
      id: this.category.id ?? null,
    }
  },
  emits: ['close'],
  methods: {
    ...mapActions('setting', ['manageCategory']),
    proceed() {
      this.errors = {}
      this.hasCategory()
      if (!this.errors.name) {
        this.loading = true
        let params = {
          name: this.name,
          id: this.id,
        }
        this.manageCategory(params)
          .then((res) => {
            const notification = {
              type: res.has_error ? 'error' : 'success',
              message: res.message,
            }
            this.$store.dispatch('notification/add', notification, { root: true })
          })
          .catch((err) => {
            console.log(err)
            this.notification(err.message)
          })
          .then(() => {
            this.loading = false
            this.emitEvent()
            this.fetchCategories()
          })
      }
    },
    notification(message, type = 'error') {
      const notification = {
        type: type,
        message: message,
      }
      this.$store.dispatch('notification/add', notification, { root: true })
    },
    isLetter($event) {
      let char = String.fromCharCode($event.keyCode)
      if (/^[A-Za-z. ]+$/.test(char)) return true
      else $event.preventDefault()
    },
    hasCategory() {
      !this.name ? (this.errors['name'] = 'The category name is required.') : ''
    },
    emitEvent() {
      this.$emit('close')
      this.errors = {}
    },
  },
}
</script>
