<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="fixed inset-0 flex z-40 md:hidden" @close="sidebarOpen = false">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="
                    ml-1
                    flex
                    items-center
                    justify-center
                    h-10
                    w-10
                    rounded-full
                    focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white
                  "
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex-shrink-0 flex items-center px-4">
              <img class="h-8 w-auto" src="/logo.png" alt="plugins" />
            </div>
            <div class="mt-5 flex-1 h-0 overflow-y-auto">
              <left-navbar-mobile :navigation="navigation" @click="sidebarOpen = false" />
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:w-52 md:flex-col md:fixed md:inset-y-0">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex-1 flex flex-col min-h-0 bg-gray-800">
        <div class="flex items-center h-16 flex-shrink-0 px-4 bg-gray-900">
          <img class="h-12 w-auto" src="/logo.png" alt="plugins" />
        </div>
        <div class="flex-1 flex flex-col overflow-y-auto">
          <left-navbar-desktop :navigation="navigation" />
        </div>
      </div>
    </div>
    <div class="md:pl-52 flex flex-col">
      <div class="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <button
          type="button"
          class="
            px-4
            border-r border-gray-200
            text-gray-500
            focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500
            md:hidden
          "
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
        </button>
        <div class="flex-1 px-4 sm:px-6 flex justify-between">
          <div class="flex-1 flex items-center">
            <h2 class="text-gray-600 text-md font-semibold">SaasPlugin Portal</h2>
          </div>
          <div class="ml-4 flex items-center md:ml-6 gap-2">
            <a
              href="https://help.saasplugin.com.au/portal/"
              target="_blank"
              class="
                bg-blue-500
                px-2
                py-1
                rounded-md
                text-sm
                font-semibold
                text-gray-50
                hover:text-gray-100
                focus:outline-none focus:ring-0
              "
            >
              Contact Support
            </a>

            <!-- Profile dropdown -->
            <Menu as="div" class="ml-3 relative">
              <div>
                <MenuButton
                  class="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-0"
                >
                  <span class="sr-only">Open user menu</span>
                  <img class="h-8 w-8 rounded-full" src="https://www.gravatar.com/avatar/HASH?d=mp" alt="" />
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="
                    origin-top-right
                    absolute
                    right-0
                    mt-2
                    w-64
                    rounded-md
                    shadow-md
                    py-1.5
                    bg-white
                    ring-1 ring-black ring-opacity-5
                    focus:outline-none
                  "
                >
                  <MenuItem>
                    <span class="block px-4 py-2 text-sm text-gray-500 w-full text-left">
                      {{ userName }}
                    </span>
                  </MenuItem>
                  <MenuItem>
                    <span class="block px-4 py-2 text-sm text-gray-500 w-full text-left">
                      {{ userEmail }}
                    </span>
                  </MenuItem>
                  <MenuItem>
                    <LogoutButton />
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>

      <main class="flex-1">
        <div class="py-6">
          <Notifications class="mt-12" />
          <div class="max-w-7xl mx-auto px-4 sm:px-6">
            <!-- Replace with your content -->
            <router-view />
            <!-- /End replace -->
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {
  CogIcon,
  PuzzleIcon,
  HomeIcon,
  MenuAlt2Icon,
  UsersIcon,
  XIcon,
  CreditCardIcon,
  //IdentificationIcon,
  //CubeTransparentIcon,
  //PlusSmIcon,
} from '@heroicons/vue/outline'
import LeftNavbarDesktop from '@/components/LeftNavbarDesktop.vue'
import LeftNavbarMobile from '@/components/LeftNavbarMobile.vue'
import Notifications from '@/components/NotificationContainer.vue'
import LogoutButton from '@/components/LogoutButton.vue'
import { mapActions, mapState } from 'vuex'

const allNavigations = [
  { id: 'Dashboard', name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: true },
  { id: 'Subscription', name: 'Subscriptions', href: '/subscriptions', icon: UsersIcon, current: false },
  { id: 'Plugin', name: 'Plugins', href: '/plugins', icon: PuzzleIcon, current: false },
  { id: 'Product', name: 'Manage Products', href: '/products', icon: CogIcon, current: false },
  { id: 'Account', name: 'Billing', href: '/billings', icon: CreditCardIcon, current: false },
  { id: 'Setting', name: 'Settings', href: '/settings', icon: CogIcon, current: false },
]

const userNavigation = [{ name: 'Sign out', href: '#', action: 'logout' }]

export default {
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    MenuAlt2Icon,
    XIcon,
    LeftNavbarDesktop,
    LeftNavbarMobile,
    Notifications,
    LogoutButton,
  },
  setup() {
    const sidebarOpen = ref(false)

    return {
      userNavigation,
      sidebarOpen,
    }
  },
  data() {
    return {
      navigation: [],
    }
  },
  methods: {
    ...mapActions('user', ['fetchPermissions', 'fetchUser']),
    logout() {
      this.$store.dispatch('user/logout')
    },
    attachNavigation() {
      this.navigation = allNavigations.filter((item) => Object.prototype.hasOwnProperty.call(this.permissions, item.id))
    },
  },
  computed: {
    ...mapState('user', ['permissions']),
    userName() {
      return this.$store.state.user.user.name
    },
    userEmail() {
      return this.$store.state.user.user.email
    },
  },
  created() {
    this.fetchUser()
    this.fetchPermissions().then(() => {
      this.attachNavigation()
    })
  },
}
</script>
