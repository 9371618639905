<template>
  <TransitionRoot as="template" :show="show">
    <Dialog as="div" class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0 bg-gray-50 opacity-50" />

        <div class="fixed mt-16 inset-y-0 pl-16 max-w-full right-0 flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-lg">
              <div class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                <div class="flex-1 h-0 overflow-y-auto">
                  <div class="pt-3 px-4 sm:px-4 flex items-start">
                    <div class="flex items-center">
                      <button
                        type="button"
                        class="
                          bg-gray-200
                          rounded-full
                          p-1
                          text-gray-500
                          hover:text-gray-600
                          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600
                        "
                        @click="$emit('close-sidebar')"
                      >
                        <span class="sr-only">Close panel</span>
                        <XIcon class="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div class="flex-1 flex flex-col justify-between">
                    <div class="divide-y divide-gray-200">
                      <div class="space-y-8 pt-4 pb-5 divide-y-2 divide-gray-200">
                        <!-- Dynamic Content -->
                        <UpgradeSubscription :subscription="findSubscriptionById(id)" @close="$emit('close-sidebar')" />

                        <!-- Add user -->
                        <div>
                          <div class="mt-4 px-4 md:px-6 flex flex-row items-end justify-between">
                            <h2 class="text-sm text-gray-600 font-semibold">Users on plan</h2>
                            <button
                              class="
                                border border-gray-400
                                bg-gray-500
                                text-white text-xs
                                px-2
                                py-1
                                font-semibold
                                rounded-md
                                cursor-default
                              "
                              disabled
                            >
                              Add member
                            </button>
                          </div>
                          <div class="mt-4">
                            <div class="overflow-x-auto">
                              <table class="min-w-full divide-y divide-gray-200">
                                <thead class="bg-gray-50">
                                  <tr>
                                    <th
                                      scope="col"
                                      class="pl-4 py-1.5 text-left text-xs font-medium text-gray-500 tracking-wider"
                                    >
                                      Name
                                    </th>
                                    <th
                                      scope="col"
                                      class="px-2 py-1.5 text-left text-xs font-medium text-gray-500 tracking-wider"
                                    >
                                      Email address
                                    </th>
                                    <th
                                      scope="col"
                                      class="px-2 py-1.5 text-left text-xs font-medium text-gray-500 tracking-wider"
                                    >
                                      Status
                                    </th>
                                    <th
                                      scope="col"
                                      class="px-2 py-1.5 text-left text-xs font-medium text-gray-500 tracking-wider"
                                    >
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody></tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'
import UpgradeSubscription from './UpgradeSubscription.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    XIcon,
    UpgradeSubscription,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      required: true,
    },
  },
  emits: ['close-sidebar'],
  computed: {
    ...mapGetters('subscription', ['findSubscriptionById']),
  },
}
</script>
