<template>
  <div>
    <label v-if="label" class="block text-xs sm:text-sm font-normal text-gray-500">{{ label }}</label>
    <div class="mt-1">
      <input
        :type="type"
        v-bind="$attrs"
        class="base-input"
        :placeholder="getPlaceholder"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
      />
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: [String, Number],
      default: '',
    },
    showPlaceholder: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  computed: {
    getPlaceholder() {
      if (!this.showPlaceholder) return ''

      if (this.placeholder) {
        return this.placeholder
      }
      return this.label
    },
  },
}
</script>
