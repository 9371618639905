<template>
  <div>
    <div class="relative rounded-md shadow-sm">
      <div class="absolute inset-y-0 left-0 pl-2.5 flex items-center">
        <SearchIcon class="absolute w-4 h-4 text-gray-600 flex-shrink-0" aria-hidden="true" />
      </div>
      <input
        :type="type"
        v-bind="$attrs"
        :class="[
          'block w-full text-gray-600 border bg-gray-50 border-gray-200 text-sm rounded-md pl-8 pr-8 py-1.5 focus:border-gray-200 hover:border-gray-200 focus:ring-0',
        ]"
        :placeholder="placeholder"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <div v-if="modelValue" class="absolute inset-y-0 z-8 right-0 pr-1.5 flex items-center">
        <div class="flex justify-center h-6 w-6 hover:bg-gray-300 rounded-full">
          <button class="text-gray-600" @click="$emit('clear')">
            <svg
              class="flex-shrink-0 h-3 w-3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              data-supported-dps="16x16"
              fill="currentColor"
              focusable="false"
            >
              <path
                d="M14 3.41L9.41 8 14 12.59 12.59 14 8 9.41 3.41 14 2 12.59 6.59 8 2 3.41 3.41 2 8 6.59 12.59 2z"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SearchIcon } from '@heroicons/vue/outline'
export default {
  inheritAttrs: false,
  components: {
    SearchIcon,
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  emits: ['update:modelValue', 'clear'],
}
</script>
