<template>
  <button
    class="
      text-gray-600
      bg-yellow-400
      shadow-md
      border border-transparent
      text-xs
      px-2
      py-1
      font-semibold
      rounded-full
      w-full
    "
  >
    Try now
  </button>
</template>
