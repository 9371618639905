import SubscriptionService from '../../services/SubscriptionService'

export const namespaced = true

export const state = {
  subscriptions: [],
  subscription: {},
  meta: {},
}
export const mutations = {
  SET_SUBSCRIPTIONS(state, subscriptions) {
    state.subscriptions = subscriptions
  },
  SET_SUBSCRIPTION_META(state, meta) {
    state.meta = meta
  },
  SET_SUBSCRIPTION(state, subscription) {
    state.subscription = subscription
  },
  UPDATE_SUBSCRIPTION_ITEM(state, { index, subscription }) {
    state.subscription[index] = subscription
  },
}
export const actions = {
  async fetchSubscriptions({ commit }, params) {
    return await SubscriptionService.fetchSubscriptions({ params })
      .then(({ data }) => {
        commit('SET_SUBSCRIPTIONS', data.data)
        commit('SET_SUBSCRIPTION_META', data.info)
      })
      .catch(() => {
        const notification = {
          type: 'error',
          message: 'Failed to fetch subscription list',
        }
        this.dispatch('notification/add', notification, { root: true })
      })
  },
  async fetchSubscription({ commit, getters }, id) {
    let subscription = getters.findSubscriptionById(id)

    if (subscription) {
      commit('SET_SUBSCRIPTION', subscription)
    } else {
      console.log('Perform API call')
    }
  },
  async upgradeSubscription(_, id) {
    return await SubscriptionService.submitUpgrade({ subscription_id: id })
  },
  async cancelSubscription({ commit, getters }, id) {
    let subscription = getters.findSubscriptionById(id)
    let index = getters.findSubscriptionIndexById(id)

    return await SubscriptionService.submitCancel({
      subscription_id: id,
    }).then(({ data }) => {
      subscription.status = data.status
      commit('UPDATE_SUBSCRIPTION_ITEM', { index, subscription })
      return Promise.resolve(data)
    })
  },
  async reactiveSubscription({ commit, getters }, id) {
    let subscription = getters.findSubscriptionById(id)
    let index = getters.findSubscriptionIndexById(id)

    return await SubscriptionService.submitReActive({
      subscription_id: id,
    }).then(({ data }) => {
      subscription.status = data.status
      commit('UPDATE_SUBSCRIPTION_ITEM', { index, subscription })

      return Promise.resolve(data)
    })
  },
  async renewSubscription(_, id) {
    return await SubscriptionService.submitRenew({ subscription_id: id })
  },
  async confirm(_, id) {
    return await SubscriptionService.confirm(id)
  },
}
export const getters = {
  findSubscriptionById: (state) => (id) => {
    return state.subscriptions.find((subscription) => subscription.id === id)
  },
  findSubscriptionIndexById: (state) => (id) => {
    return state.subscriptions.findIndex((subscription) => subscription.id === id)
  },
  subsCount(state) {
    return state.subscriptions.length
  },
}
