import PluginService from '../../services/PluginService'
import SubscriptionService from '../../services/SubscriptionService'
import isFileExist from '../../mixins/isFileExists'

export const namespaced = true

export const state = {
  plugins: [],
  pluginId: null,
  plugin: {},
  pluginInfo: [],
}
export const mutations = {
  SET_PLUGIN_INFO(state, pluginInfo) {
    state.pluginInfo = pluginInfo
  },
  SET_PLUGINS_DATA(state, { data, page }) {
    if (page == 1) {
      state.plugins = data
    } else {
      state.plugins.data.push(...data.data)
      state.plugins.info = data.info
    }
  },
  SET_PLUGIN_DATA(state, { data }) {
    state.plugins.data = data
  },
  SET_PLUGIN_ID(state, pluginId) {
    state.pluginId = pluginId
  },
  SET_PLUGIN_DETAILS(state, pluginDetails) {
    state.plugin = pluginDetails
  },
  UPDATE_PLUGIN_DATA(state, pluginData) {
    const index = state.plugins.data.findIndex((item) => item.id === pluginData.id)
    if (index !== -1) {
      state.plugins.data.splice(index, 1, pluginData)
    }
  },
  UPDATE_PLUGIN_ITEM(state, { index, plugin }) {
    state.plugin[index] = plugin
  },
}
export const actions = {
  plugins({ commit }, params) {
    let page = params.page
    return PluginService.plugins({ params }).then(({ data }) => {
      commit('SET_PLUGINS_DATA', { data, page })
    })
  },
  nonSubscribePlugin({ commit }, params) {
    let page = params.page
    return PluginService.nonSubscribePlugin({ params }).then(({ data }) => {
      commit('SET_PLUGINS_DATA', { data, page })
    })
  },
  getPluginDetailsById({ commit }, params) {
    return PluginService.pluginDetailsById({ params }).then(({ data }) => {
      commit('SET_PLUGIN_DETAILS', data.data)
    })
  },
  async update({ commit, getters }, params) {
    let id = Object.fromEntries(params).id
    let status = Object.fromEntries(params).status
    let plugin = getters.findPluginById(id)
    let index = getters.findPluginIndexById(id)
    // return PluginService.pluginDetailsUpdate(params)
    return await PluginService.pluginDetailsUpdate(params).then(({ data }) => {
      let response = data
      if (response.has_error) {
        return Promise.reject(response)
      }
      plugin.status = status
      commit('UPDATE_PLUGIN_ITEM', { index, plugin })

      return Promise.resolve(response)
    })
  },
  setPluginId({ commit }, params) {
    commit('SET_PLUGIN_ID', params)
  },
  setPluginInfo({ commit }, params) {
    let data = {
      id: params.id,
      planId: params.planId,
      subscriptionId: params.subscriptionId,
      name: params.name,
      logo: isFileExist.checkImage(params.logo),
      interval: params.interval,
      amount: params.amount,
      url: params.url,
      status: params.status,
    }
    commit('SET_PLUGIN_INFO', data)
  },
  sync() {
    return PluginService.pluginSync()
  },
  getCategories() {
    return PluginService.categories()
  },
  //Try Now implementation
  async tryNow({ commit, state }, params) {
    return await SubscriptionService.tryNow(params).then(({ data }) => {
      let index = state.plugins.data.findIndex((x) => x.id === params.plan_id)
      state.plugins.data.splice(index, 1)
      commit('SET_PLUGIN_ID', params.plan_id)
      commit('SET_PLUGIN_DATA', { data: state.plugins.data })

      return Promise.resolve(data)
    })
  },
}
export const getters = {
  getPluginId(state) {
    return state.pluginId
  },
  getPluginDetails(state) {
    return state.plugin
  },
  findPluginById: (state) => (id) => {
    return state.plugins.data.find((plugin) => plugin.id === parseInt(id))
  },
  findPluginIndexById: (state) => (id) => {
    return state.plugins.data.findIndex((plugin) => plugin.id === parseInt(id))
  },
  getPluginInfo(state) {
    return state.pluginInfo
  },
  plugins(state) {
    return state.plugins && state.plugins.data
  },
  info() {
    return state.plugins && state.plugins.info
  },
}
