<template>
  <textarea
    ref="textarea"
    class="
      mt-1
      block
      w-full
      bg-gray-50
      border border-gray-100
      rounded-md
      px-3
      focus:outline-none
      sm:text-sm
      resize-none
      overflow-hidden
    "
    :value="modelValue"
    :autocomplete="autocomplete"
    @focus="autoResize"
    @input="fireEvent($event.target.value)"
    :placeholder="placeholder"
  ></textarea>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    autocomplete: {
      type: String,
      default: 'off',
    },
    placeholder: {
      default: 'Descriptions...',
    },
    modelValue: String,
  },
  emits: ['update:modelValue'],
  methods: {
    autoResize() {
      this.$refs.textarea.style.height = 'auto'
      this.$refs.textarea.style.height = `${this.$refs.textarea.scrollHeight}px`
    },
    reset() {
      this.$refs.textarea.style.height = ''
    },
    fireEvent(value) {
      this.$emit('update:modelValue', value)
      this.autoResize()
    },
  },
}
</script>
