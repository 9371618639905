<template>
  <nav class="flex-1 px-2 py-4 space-y-1">
    <ul>
      <li v-for="item in navigation" :key="item.name">
        <router-link
          :to="item.href"
          active-class="bg-gray-900 text-white"
          :class="[
            activeLink(item.href) ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
            'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
          ]"
        >
          <component
            :is="item.icon"
            :class="[
              item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
              'mr-3 flex-shrink-0 h-6 w-6',
            ]"
            aria-hidden="true"
          />
          {{ item.name }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    navigation: {
      require: true,
    },
  },
  methods: {
    activeLink(path) {
      let currentElement = this.$route.path.split('/').filter((d) => {
        return d && path.indexOf(d) != -1
      })
      return currentElement.length > 0
    },
  },
}
</script>
