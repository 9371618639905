<template>
  <TransitionRoot :show="show" as="template">
    <Dialog as="div" @close="$emit('close')">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center bg-gray-200 bg-opacity-50">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true"> &#8203; </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="
                inline-block
                w-full
                max-w-lg
                p-6
                my-8
                overflow-hidden
                text-left
                align-middle
                transition-all
                transform
                bg-white
                shadow-xl
                rounded-2xl
                space-y-6
              "
            >
              <DialogTitle as="h3" class="px-2 pt-2 text-lg font-medium leading-4 text-red-600">
                Payment Method Alert</DialogTitle
              >
              <div class="">
                <p class="px-2 text-sm font-medium text-gray-600 text-justify">
                  Sorry, an active paid subscription with an included payment method is required to use this feature.
                </p>
                <p class="px-2 mt-3 text-sm font-medium text-gray-600 text-justify">
                  Once a paid subscription with an included payment method has been added to your account, you will be
                  able change or remove the payment method for the selected subscription.
                </p>
              </div>

              <div class="mt-6 flex justify-center">
                <button
                  type="button"
                  class="
                    inline-flex
                    items-center
                    justify-center
                    px-4
                    py-1.5
                    text-sm
                    font-medium
                    text-blue-900
                    bg-blue-100
                    border border-transparent
                    rounded-md
                    hover:bg-blue-200
                    focus:outline-none
                    focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500
                  "
                  @click="$emit('close')"
                >
                  Close
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { TransitionRoot, TransitionChild, Dialog, DialogOverlay, DialogTitle } from '@headlessui/vue'

export default {
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    payment: {
      type: String,
      default: null,
    },
    card: {
      type: Object,
    },
  },
  data() {
    return {}
  },
  emits: ['close'],
}
</script>
