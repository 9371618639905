<template>
  <div class="pb-4 inline-block">
    <router-link :to="{ name: 'product', params: { id: $route.params.id } }"
      ><ArrowLeftIcon class="h-5 w-5"
    /></router-link>
  </div>
  <div class="bg-white pb-8 pt-4 px-8 rounded-md shadow">
    <h2 class="text-gray-600 text-md font-semibold py-4">Publish a new plugin</h2>
    <div class="flex flex-col gap-6">
      <div class="flex flex-row justify-start">
        <div class="">
          <span>Upload Logo</span>
          <img src="/inventory.png" alt="" class="h-16 w-16 rounded-full shadow border-2 border-gray-100" />
          <span>Upload</span>
        </div>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-16">
        <div class="flex flex-col gap-6">
          <div>
            <text-input label="Plugin name" placeholder="Ex: Xero2Zoho" />
          </div>
          <div class="flex flex-row gap-2 justify-between">
            <base-select :options="['CRM', 'DESK']" label="Plugin category" class="flex-grow" />
            <div class="self-end">
              <secondary-button class="bg-blue-200">Add new </secondary-button>
            </div>
          </div>
          <div>
            <text-input label="Description" placeholder="Description goes here" />
          </div>
          <div>
            <base-select :options="['Basic', 'Premium']" label="Zoho subscription plan" />
          </div>
        </div>
        <div class="flex flex-col gap-6">
          <div>
            <text-input label="Plugin URL" placeholder="Ex: xero2zoho.oscillosoft....." />
          </div>
          <div>
            <text-input label="Wordpress URL" placeholder="Ex: wordpress.oscillosoft....." />
          </div>
          <div>
            <text-input label="Zoho marketplace URL" placeholder="Ex: wordpress.oscillosoft....." />
          </div>
          <div class="">
            <base-select :options="['Active', 'Disable']" label="Current status" />
          </div>
        </div>
      </div>
      <div class="flex flex-row justify-end">
        <submit-button type="submit">Publish</submit-button>
      </div>
    </div>
  </div>
</template>

<script>
// import Plugin from '../shared/Plugin.vue'
import SubmitButton from '../../components/PrimaryButton.vue'
import SecondaryButton from '../../components/SecondaryButton.vue'
import TextInput from '../../components/BaseInput.vue'
import BaseSelect from '../../components/BaseSelect.vue'
// import BaseCheckbox from '../../components/BaseCheckbox.vue'
// import BaseRadio from '../../components/BaseRadio.vue'
// import BaseRadioGroup from '../../components/BaseRadioGroup.vue'
import { ArrowLeftIcon } from '@heroicons/vue/outline'

const plugins = [
  { id: 1, name: 'Xero 2 Inventory Plugin', logo: '/inventory.png' },
  { id: 2, name: 'Xero 2 CRM', logo: '/crm.png' },
  { id: 3, name: 'Google Map 2 CRM', logo: '/googlemap.png' },
  { id: 4, name: 'CRM 2 Moodle Real naming issues is here', logo: '/moodle.png' },
]
export default {
  components: {
    ArrowLeftIcon,
    // Plugin,
    TextInput,
    BaseSelect,
    SubmitButton,
    SecondaryButton,
    // BaseCheckbox,
    // BaseRadio,
    // BaseRadioGroup,
  },
  data() {
    return {
      plugins,
      firstName: null,
      position: null,
      checkbox: true,
      radio: 1,
      radiogroup: 1,
    }
  },
}
</script>
