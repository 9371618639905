<template>
  <BaseButton :type="type" class="btn-primary">
    <slot></slot>
  </BaseButton>
</template>

<script>
import BaseButton from './BaseButton.vue'

export default {
  components: {
    BaseButton,
  },
  props: {
    type: {
      type: String,
      default: 'button',
    },
  },
}
</script>
