import { createApp } from 'vue'
import router from './router'
import store from './store'
import Shell from './Shell.vue'

import './css/index.css'

const app = createApp(Shell)

app.use(store).use(router).mount('#app')
