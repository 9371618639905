<template>
  <div class="border border-gray-200 shadow px-4 py-6 w-48 sm:w-52 overflow-hidden bg-white rounded-md">
    <div class="flex flex-col items-center gap-2 sm:gap-6 h-full justify-between">
      <div class="flex flex-col items-center gap-4">
        <img :src="logo" alt="plugin logo" class="w-24 h-24" />
        <div class="flex flex-col gap-1">
          <p class="text-gray-800 text-xs font-semibold text-center">{{ pluginName }}</p>
          <p :class="[subscriptionCard.textColor, 'text-xs font-bold text-center']">{{ subscriptionCard.status }}</p>
          <p v-if="subscriptionCard.hasTrial" class="text-gray-500 text-xs font-medium text-center">
            {{ subscriptionCard.trialPeriod }}
          </p>
        </div>
      </div>
      <!-- <slot>
        <button
          :class="[
            cursorEvent,
            subscriptionCard.theme,
            'w-full border border-transparent shadow-md text-xs px-2 py-1.5 font-medium rounded-full self-end',
          ]"
          @click="emitEvent"
        >
          {{ subscriptionCard.btnText }}
        </button>
      </slot> -->
    </div>
  </div>
</template>

<script>
import useSubscription from '../../composables/useSubscription'
export default {
  props: {
    subscription: {
      type: Object,
      required: true,
    },
    area: {
      type: String,
    },
  },
  emits: ['open-sidebar'],
  data() {
    const subscriptionCard = useSubscription(this.subscription)
    return {
      plan: this.subscription.plan ? this.subscription.plan : {},
      subscriptionCard,
    }
  },
  methods: {
    emitEvent() {
      if (this.subscriptionCard.status == 'Active') {
        this.$emit('cancel-subscription')
      } else {
        this.$emit('open-sidebar')
      }
    },
  },
  computed: {
    logo() {
      return this.plan.logo ? this.plan.logo : 'plugin-logo.png'
    },
    pluginName() {
      return this.plan.name || ''
    },
  },
}
</script>
