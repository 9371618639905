import http from './Http/Client'

export default {
  login(data) {
    return http.post('/login', data)
  },
  logout() {
    return http.post('/logout')
  },
  register(data) {
    return http.post(process.env.VUE_APP_API_PREFIX + 'user/register', data)
  },
  confirmVerification(path) {
    return http.get(path)
  },
  resendVerification() {
    return http.post('/email/verification-notification')
  },
  reset() {
    //Implement reset login
    //return http.post('/reset-password', data)
  },
  forgotPassword(data) {
    return http.post('/forgot-password', data)
  },
  resetPassword(data) {
    return http.post('/reset-password', data)
  },
  checkRegistration() {
    return http.get(process.env.VUE_APP_API_PREFIX + 'user/check')
  },
  user() {
    return http.get(process.env.VUE_APP_API_PREFIX + 'user/me')
  },
  permissions() {
    return http.get(process.env.VUE_APP_API_PREFIX + 'user/permissions')
  },
}
