import DashboardService from '../../services/DashboardService'

export const namespaced = true

export const state = {
  subscriptions: [],
}
export const mutations = {
  SET_SUBSCRIPTIONS_DATA(state, data) {
    state.subscriptions = data
  },
}
export const actions = {
  recentSubscriptionUsed({ commit }, params) {
    return DashboardService.subscriptions({ params }).then(({ data }) => {
      commit('SET_SUBSCRIPTIONS_DATA', data)
    })
  },
}
export const getters = {
  subscriptions(state) {
    return state.subscriptions && state.subscriptions.data
  },
  info() {
    return state.subscriptions && state.subscriptions.info
  },
}
