import http from './Http/Client'

export default {
  fetchSubscriptions(params) {
    return http.get(process.env.VUE_APP_API_PREFIX + 'subscription/my-subscriptions', params)
  },
  submitTryNow(params) {
    return http.post(process.env.VUE_APP_API_PREFIX + 'subscription/try-now', params)
  },
  submitUpgrade(params) {
    return http.post(process.env.VUE_APP_API_PREFIX + 'subscription/upgrade-subscription', params)
  },
  submitCancel(params) {
    return http.post(process.env.VUE_APP_API_PREFIX + 'subscription/cancel-subscription', params)
  },
  submitReActive(params) {
    return http.post(process.env.VUE_APP_API_PREFIX + 'subscription/reactivate-subscription', params)
  },
  submitRenew(params) {
    return http.post(process.env.VUE_APP_API_PREFIX + 'subscription/renew-subscription', params)
  },
  confirm(params) {
    return http.post(process.env.VUE_APP_API_PREFIX + 'subscription/thank-you', params)
  },
  updateRecent(params) {
    return http.post(process.env.VUE_APP_API_PREFIX + 'subscription/set-recently-used', params)
  },
  // Try now api endpoint
  tryNow(params) {
    return http.post(process.env.VUE_APP_API_PREFIX + 'subscription/try-now', params)
  },
}
